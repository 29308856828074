import React from "react";
import styled from "styled-components";
import { darkCardStyle  } from "src/GlobalStyle";
import { useTranslation } from "react-i18next";
import { Table } from "react-bootstrap";
import { formatAmount, formatDate, formatTime } from "src/utils/formatters";
import { formatFreePlayName } from "src/utils/accountUtils";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";

const PointsCard = ({ earnedTransactions = [], redeemedTransactions = [] }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const totalEarned = earnedTransactions.reduce((sum, txn) => sum + txn.points, 0);
  const totalRedeemed = redeemedTransactions.reduce((sum, txn) => sum + txn.points, 0);


  return (
    <CardDiv className="my-3 p-4">
      <SubTitleSection subTitle={t("myAccount.rewardsPointsHistory")} className="pt-0" />
      <SubTitleSection as={"h3"} subTitle={t("myAccount.latestRewardsPointsEarned")} text={t("myAccount.pointsInPlayNote")} />

      <StyledTable striped responsive variant="dark">
        <thead>
          <tr>
            <th>{t("myAccount.date")}</th>
            <th>{t("myAccount.time")}</th>
            <th>{t("myAccount.game")}</th>
            <th className="text-end">{t("myAccount.points")}</th>
          </tr>
        </thead>
        <tbody>
          {earnedTransactions.length > 0 ? (
            earnedTransactions.slice(0, 30).map((txn, index) => (
              <tr key={index}>
                <td>{formatDate(txn.date)}</td>
                <td>{formatTime(txn.date)}</td>
                <td>{txn.game}</td>
                <td className="text-end">{formatAmount(txn.points, lang, false)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4"></td>
            </tr>
          )
          }
          <tr className="totals-row d-none">
            <td colSpan="4" className="text-end fw-bold">{t("myAccount.total")}</td>
            <td className="text-end fw-bold">{formatAmount(totalEarned, lang, false)}</td>
          </tr>
        </tbody>
      </StyledTable>

      <SubTitleSection as={"h3"} subTitle={t("myAccount.latestRewardsPointsRedeemed")} />

      <StyledTable striped responsive variant="dark">
        <thead>
          <tr>
            <th>{t("myAccount.date")}</th>
            <th>{t("myAccount.time")}</th>
            <th>{t("myAccount.name")}</th>
            <th className="text-end">{t("myAccount.points")}</th>
          </tr>
        </thead>
        <tbody>
          {redeemedTransactions.length > 0 ? (
            redeemedTransactions.slice(0, 30).map((txn, index) => (
              <tr key={index}>
                <td>{formatDate(txn.date)}</td>
                <td>{formatTime(txn.date)}</td>
                <td>{formatFreePlayName(txn.name, lang)}</td>
                <td className="text-end">{formatAmount(txn.points, lang, false)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4"></td>
            </tr>
          )}
          <tr className="totals-row  d-none">
            <td colSpan="4" className="text-end fw-bold">{t("myAccount.total")}</td>
            <td className="text-end fw-bold">{formatAmount(totalRedeemed, lang, false)}</td>
          </tr>
        </tbody>
      </StyledTable>
    </CardDiv>
  );
};

const CardDiv = styled.div`
  ${darkCardStyle}
  padding: 2rem;
  color: var(--mutedwhite);
  .subtitle-section {
    padding-bottom: 1rem;
    p {
      margin-bottom: 0;
    }
  }
`;

const StyledTable = styled(Table)`
  width: 100%;
`;

export default PointsCard;
