import { useState, useEffect } from "react";
import { Spinner, Alert, Form, FormLabel } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import { StyledForm } from "../../GlobalStyle";
import Button from "../Button/Button";
import styled from "styled-components";
import { useToast } from "src/context/toast/toast.provider";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";


const GoogleFormSubmitter = ({ formId, promoSourceRef }) => {
  const { t } = useTranslation();
  const { showToastFn } = useToast();
  const [promoSource, setPromoSource] = useState(promoSourceRef);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [playerId, setPlayerId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (isSuccess == true) {
      if (promoSource === "Quantcast") {
        const orderID = `${firstName}_${lastName}_${phone.slice(-3)}`;
        console.log("Entry submitted for", orderID);
        window._qevents = window._qevents || [];
        window._qevents.push({
          qacct: "p-vXc3n0eGxvxS3",
          labels: "_fp.event.Sign Up Complete",
          orderid: orderID,
          event: "refresh",
        });
      }
    }
  }, [isSuccess]);

  // Test sheet
  // formId = 'AKfycbzJOJVlOFRgYmIF3C8rp9nIFaUUmzdCk_XrFBE5Za-3yCoqdf_GTxpFCXX-K7eq79vmhA';
  // https://script.google.com/macros/s/AKfycbzJOJVlOFRgYmIF3C8rp9nIFaUUmzdCk_XrFBE5Za-3yCoqdf_GTxpFCXX-K7eq79vmhA/exec

  const formUrl = `https://script.google.com/macros/s/${formId}/exec`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");

    const formData = new FormData();
    formData.append("Timestamp", new Date());
    formData.append("FirstName", firstName);
    formData.append("LastName", lastName);
    formData.append("Email", email);
    formData.append("PhoneNumber", phone);
    formData.append("PlayerID", playerId);
    formData.append("PromoSource", promoSource);

    try {
      const response = await fetch(formUrl, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        showToastFn({
          show: true,
          title: t("wsopcForm.error.title"),
          message: t("wsopcForm.error.text"),
          autohide: false,
          variant: "danger",
        });
        return;
      }

      showToastFn({
        show: true,
        title: t("wsopcForm.success.title"),
        message: t("wsopcForm.success.text"),
        autohide: true,
        variant: "success",
      });

      setIsSuccess(true);

      // setFirstName("");
      // setLastName("");
      // setEmail("");
      // setPhone("");
      // setPlayerId("");
      // setPromoSource("");
    } catch (error) {
      setErrorMessage("Submission failed. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FormContainer>
      {isSuccess && (
        <SuccessOverlay>
          <ResponsiveImage
            src="svg/Check.svg"
            alt="check-icon"
          />
          <h2>{t("wsopcForm.success.title")}</h2>
          <p>{t("wsopcForm.success.text")}</p>
        </SuccessOverlay>
      )}
      <StyledForm onSubmit={handleSubmit} className="form-container">
        <SubTitleSection
          subTitle={t("wsopcForm.enterNow")}
          as={"h1"}
          text={t("wsopcForm.qualify")}
        />

        <Form.Group controlId="formFirstName">
          <FormLabel>{t("wsopcForm.firstName")}</FormLabel>
          <Form.Control
            type="text"
            name="fname"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder={t("wsopcForm.firstName")}
            required
          />
        </Form.Group>

        <Form.Group controlId="formLastName">
          <FormLabel>{t("wsopcForm.lastName")}</FormLabel>
          <Form.Control
            type="text"
            name="lname"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder={t("wsopcForm.lastName")}
            required
          />
        </Form.Group>

        <Form.Group controlId="formEmail">
          <FormLabel>{t("wsopcForm.email")}</FormLabel>
          <Form.Control
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder={t("wsopcForm.email")}
            required
          />
        </Form.Group>

        <Form.Group controlId="formPhone">
          <FormLabel>{t("wsopcForm.phoneNumber")}</FormLabel>
          <Form.Control
            type="text"
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder={t("wsopcForm.phoneNumber")}
            required
          />
        </Form.Group>

        <Form.Group controlId="formPlayerId">
          <FormLabel>{t("wsopcForm.pgPlayerId")}</FormLabel>
          <Form.Control
            type="text"
            name="playerId"
            value={playerId}
            onChange={(e) => setPlayerId(e.target.value)}
            placeholder={t("wsopcForm.pgPlayerIdPlayceholder")}
          />
        </Form.Group>

        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

        <Button
          type="submit"
          disabled={isLoading}
          bgColor="var(--red)"
          color="var(--white)"
          hoverColor="var(--black)"
          hoverBgColor="var(--white)"
          fluid
          label={
            isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              t("wsopcForm.submit")
            )
          }
        />
      </StyledForm>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  position: relative;
`;

const SuccessOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.95);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 3rem 3rem 4rem;
  z-index: 3;
  img {
    max-width: 3rem;
    margin: 0.5rem;
  }
`;

export default GoogleFormSubmitter;
