import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import OptInPreferences from "../components/Account/OptInPreferences";
import UpdatePassword from "../components/Account/UpdatePassword";
import Hero from "src/components/Hero/Hero";
import { darkCardStyle  } from "src/GlobalStyle";
import useResponsiveImageUrl from "../utils/useResponsiveImageUrl";


const AccountSettings = () => {
  const { t } = useTranslation();
  const responsiveBgImage = useResponsiveImageUrl("about/about-background.jpg");

  return (
    <SettingsDiv bgImage={responsiveBgImage}>
        <Hero
          mainHeroText={t("myAccount.accountSettings")}
          description={t("myAccount.accountSettingsDescription")}
          bgImage={"about/about-us-hero-bg_v2.jpg"}
          darkenBg={true}
        />
        <container-div>
          <SettingsContainer className="my-2" xs={1} md={2}>
            <Col>
              <Inner>
                <OptInPreferences myAccount={true} />
              </Inner>
            </Col>
            <Col>
              <Inner>
                <UpdatePassword />
              </Inner>
            </Col>
          </SettingsContainer>
        </container-div>
    </SettingsDiv>
  );
};

const SettingsDiv = styled.div`
  width: 100%;
  background-color: #2d3436;
  background: #000 url(${(props) => props.bgImage}) 50% 50% fixed no-repeat;
  background-size: cover;
  .hero_container {
    background-position: top;
    h1 {
      margin: 0;
      padding: 3rem 1rem;
      font-size: 2rem;
    }
  }
  .subtitle-section {
    padding-top: 0;
  }
  form {
    margin: 0;
  }
`;

const SettingsContainer = styled(Row)`
  /* background-color: var(--lightblack); */
  color: var(--white);
  .col {
    padding: 1rem;
    height: 100%;
  }
  form {
    margin: 0;
    border: 0;
    padding: 0;
  }
  ;`

const Inner = styled.div`
  ${darkCardStyle}
  padding: 2rem;
`;


export default AccountSettings;
