import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Row, Spinner } from "react-bootstrap";
import { useErs } from "src/context/ers/ers.provider";
import { useTranslation } from "react-i18next";
import TitleSection from "src/components/TitleSection/TitleSection";
import GameListCard from "src/components/ElectronicGamingDevices/GameListCard";
import {
  getHighestJackpotForGame,
} from "src/utils/gameListUtils";
import Hero from "src/components/Hero/Hero";

const ChineseNewYear2025Games = () => {
  const { i18n, t } = useTranslation();
  const { data, fetchData } = useErs();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // Fetch both cny game data
  useEffect(() => {
    setLoading(true);
    Promise.all([
      fetchData("egd-game-list"),
      fetchData("egd-progressive-jackpots"),
    ])
      .then(([gameData, jackpotData]) => {
        setLoading(false);
        setError(null);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.message || t("error.somethingWentWrong"));
        console.error("Error fetching data:", err);
      });
  }, [fetchData, t]);

  const gameList = data?.["egd-game-list"];
  const jackpotList = data?.["egd-progressive-jackpots"];

  return (
    <>
      <JackpotPage>
        <Hero
          {...{
            bgImage: "promotions/cny-2025/1600x500_CNY_LP.png",
            image: `promotions/cny-2025/1600x800_CNY_Hero-Carousel_Logo_v3_${i18n.language}.png`,
            darkenBg: 0.5,
            overlayVideoSrc: "/videos/bbj-overlay.mp4",
          }}
        ></Hero>
        <container-wide>
          {/* Dynamic Content */}
          <TitleSection
            title={t("chineseNewYear2025.hero.title")}
            text={t("chineseNewYear2025.hero.text")}
          />
          {loading ? (
            <SpinnerHolder>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">{t("loading")}</span>
              </Spinner>
            </SpinnerHolder>
          ) : error ? (
            <div className="text-center text-danger">
              <p>
                {t("error.somethingWentWrong")}: {error}
              </p>
            </div>
          ) : (
            <Row className="g-4 gx-3" xs={1} md={2} lg={3}>
              {gameList?.map((game, index) => {
                const highestJackpot = getHighestJackpotForGame(
                  game,
                  jackpotList
                );

                return (
                  game.status === "CNY" && (
                    <GameListCard
                      key={game.game_name}
                      game={game}
                      jackpot={highestJackpot}
                    />
                  )
                );
              })}
            </Row>
          )}
          <Disclaimer className="text-center">
            *{t("egd.egdGameList.progressive_note_1")}
          </Disclaimer>
        </container-wide>
      </JackpotPage>
    </>
  );
};

const JackpotPage = styled.div`
  background-color: var(--black);
  .hero_text_container {
    display: none;
  }
  .title-section {
    p {
      padding: 0;
    }
  }
`;

const Disclaimer = styled.p`
  color: var(--mustard);
  font-size: 0.9rem;
  margin-top: 1rem;
`;

const TabContent = styled.div`
  background: var(--lightblack);
  padding: 0 1rem 1rem;
  margin-bottom: 2rem;
`;

const SpinnerHolder = styled.div`
  display: flex;
  justify-content: center;
  margin: 40px;
`;

export default ChineseNewYear2025Games;
