import React, { useState } from "react";
import styled from "styled-components";
import {
  FormGroup,
  FormControl,
  FormLabel,
  Spinner,
  Alert,
} from "react-bootstrap";
import { BsFillLockFill } from "react-icons/bs";
import { StyledForm } from "src/GlobalStyle";
import { change_password } from "src/api/auth";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button/Button";
import SubTitleSection from "../SubTitleSection/SubTitleSection";
import Cookies from "js-cookie";
import PasswordTooltip from "src/components/Auth/PasswordTooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

const UpdatePassword = () => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const oldPassword = formData.get("oldPassword");
    const newPassword = formData.get("newPassword");
    const confirmPassword = formData.get("confirmPassword");

    setIsLoading(true);
    setErrorMessages([]);

    if (newPassword !== confirmPassword) {
      setErrorMessages(["passwordMismatch"]);
      setIsLoading(false);
      return;
    }

    if (oldPassword === newPassword) {
      setErrorMessages(["samePassword"]);
      setIsLoading(false);
      return;
    }

    try {
      const response = await change_password(
        Cookies.get("access_token"),
        oldPassword,
        newPassword,
        confirmPassword
      );

      if (response.success) {
        setErrorMessages(["success"]);
      } else if (
        Array.isArray(response.messages) &&
        response.messages.length > 0
      ) {
        setErrorMessages(response.messages);
      } else {
        setErrorMessages(["error"]);
      }
    } catch (error) {
      console.error("Error changing password:", error);
      setErrorMessages(["catchError"]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <UpdatePasswordDiv>
      <SubTitleSection
        subTitle={t("myAccount.changeYourPassword")}
        text={t("myAccount.useThisFeature")}
      />
      <StyledForm onSubmit={handleSubmit}>
        <FormGroup>
          <FormLabel htmlFor="oldPassword">
            <BsFillLockFill /> {t("myAccount.oldPassword")}
          </FormLabel>
          <FormControl
            id="oldPassword"
            type="password"
            name="oldPassword"
            required
          />
        </FormGroup>

        <FormGroup>
          <div className="label-link-container">
            <FormLabel htmlFor="newPassword">
              {" "}
              <BsFillLockFill /> {t("myAccount.newPassword")}
            </FormLabel>
            <PasswordTooltip placement="left">
              <>
                <small className="form-link">info</small>
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  aria-hidden="true"
                  className="caret form-link"
                />
              </>
            </PasswordTooltip>
          </div>
          <FormControl
            id="newPassword"
            type="password"
            name="newPassword"
            required
          />
        </FormGroup>

        <FormGroup>
          <FormLabel htmlFor="confirmPassword">
            <BsFillLockFill /> {t("myAccount.repeatNewPassword")}
          </FormLabel>
          <FormControl
            id="confirmPassword"
            type="password"
            name="confirmPassword"
            required
          />
        </FormGroup>

        <Button
          className="mt-4"
          type="submit"
          disabled={isLoading}
          bgColor="var(--red)"
          color="var(--white)"
          hoverColor="var(--black)"
          hoverBgColor="var(--white)"
          fluid
          label={
            isLoading ? (
              <Spinner as="span" animation="border" size="sm" />
            ) : (
              t("myAccount.update")
            )
          }
        />

        {errorMessages.length > 0 && (
          <Alert
            variant={errorMessages.includes("success") ? "success" : "danger"}
            className="mt-3"
          >
            <ul>
              {errorMessages.map((el) => (
                <li key={el}>{t(`auth.passwordValidations.${el}`)}</li>
              ))}
            </ul>
          </Alert>
        )}
      </StyledForm>
    </UpdatePasswordDiv>
  );
};

const UpdatePasswordDiv = styled.div`
  .label-link-container {
    left: 0;
  }
`;

export default UpdatePassword;
