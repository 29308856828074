import React from "react";
import styled from "styled-components";
import { Container, Col, Row, ProgressBar, Tooltip, OverlayTrigger } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { getTotalProgressPercentage } from "src/utils/accountUtils";
import { MdInfo } from "react-icons/md";
import { formatAmount } from "src/utils/formatters";


const TOTAL_TIERS = 4;
const TIER_WIDTH = 100 / TOTAL_TIERS; // Each tier takes up 25%

const StatusBanner = ({
    tierBalance = 0,
    currentTier = 0,
    tierFromCurrentBalance = 0,
    // previousTier = 0,
    // previousTierBalance = 0,
    isVip = false
  }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  const percentage = getTotalProgressPercentage(tierBalance, tierFromCurrentBalance);

  // **🔹 Ensure the pointer stops at 87.5% when at 100%**
  const adjustedPointerPercentage = percentage < 100 ? percentage : 87.5;

  // **🔹 Cap both the progress bar and pointer within the current tier boundary**
  // Conditionally, isVip progress and pointer are always at 100% and 87.5% respectively
  const maxPercentageForTier = tierFromCurrentBalance * TIER_WIDTH;
  const cappedProgressPercentage = isVip ? 100 : Math.min(percentage, maxPercentageForTier); // ✅ Progress capped at tier max
  const cappedPointerPercentage = isVip ? 87.5 : Math.min(adjustedPointerPercentage, maxPercentageForTier); // ✅ Pointer capped at tier max

  return (
    <Container fluid className="pb-3">
      <TierRow className={`mb-0 ${ isVip ? "vip" : ""}`}>
        {isVip ? (
          <>
            {["tier-1", "tier-2", "tier-3", "tier-4"].map((tier, index) => (
              <Col key={tier}>
                <div className={`tier-holder ${tier}`}>
                  <span>
                    {t(`myAccount.${tier}`)}
                  </span>
                </div>
              </Col>
            ))}
            <Col className="active">
              <div className={`tier-holder tier-white`}>
                <span>
                  {t(`myAccount.white`)}
                </span>
              </div>
            </Col>
          </>
        ) : (
          ["tier-1", "tier-2", "tier-3", "tier-4"].map((tier, index) => (
            <Col key={tier} className={`${currentTier === index + 1 ? "active" : ""}`}>
              <div className={`tier-holder ${tier}`}>
                <span>
                  {t(`myAccount.${tier}`)}
                  <OverlayTrigger placement="top" overlay={
                    <Tooltip id={`tier-${index + 1}-tooltip`}>
                      {t(`rewards.section-2-points-${index + 1}`)}
                    </Tooltip>
                  }>
                    <InfoIconTrigger className="overlay-trigger small bump-up more ms-2">
                      <MdInfo />
                    </InfoIconTrigger>
                  </OverlayTrigger>
                </span>
              </div>
            </Col>
          ))
        )}
      </TierRow>
      <Row className="mb-1">
        <Col className="p-0">
          <StyledProgressBar
            animated
            className={ isVip ? "tier-white" : `tier-${tierFromCurrentBalance}`}
            now={cappedProgressPercentage > 1 ? cappedProgressPercentage : 1} // ✅ Progress bar capped at tier max (change to 1% if 0)
          />
          <PointerHolder
            percentage={cappedPointerPercentage > 1 ? cappedPointerPercentage : 1} // ✅ Pointer capped at tier max (change to 1% if 0)
            className={ isVip ? "tier-white" : `tier-${tierFromCurrentBalance}`}
            tierBalance={formatAmount(tierBalance, lang, false)}
          />
        </Col>
      </Row>
    </Container>
  );
};

const PointerHolder = styled.div`
  position: relative;
  width: 100%;
  height: 14px;

  ${({ tierBalance }) => `--tier-balance: "${tierBalance}";`}
  &:after {
    content: var(--tier-balance);
    left: ${({ percentage }) => `calc(${percentage}% - 3rem)`}; /* Dynamic position */
    position: absolute;
    top: 15px;
    width: 6rem;
    text-align: center;
    font-size: 0.8rem;
    letter-spacing: 0.5px;
    color: var(--mutedwhite);
  }

  &:before {
    content: "▲"; /* Upward pointer */
    width: 14px;
    font-size: 14px;
    position: absolute;
    top: -1px;
    left: ${({ percentage }) => `calc(${percentage}% - 7px)`}; /* Dynamic position */
    z-index: 3;
    .tier-1& { color: var(--tier-1-highlight); }
    .tier-2& { color: var(--tier-2-highlight); }
    .tier-3& { color: var(--tier-3-highlight); }
    .tier-4& { color: var(--tier-4-highlight); }
    .tier-white& { color: var(--tier-white); }
  }
`;

const StyledProgressBar = styled(ProgressBar)`
  border-radius: 0;
  position: relative;
  .progress-bar {
    border-radius: 0;
    transition: var(--bs-progress-bar-transition);
    .tier-1& { background-color: var(--tier-1-silver); }
    .tier-2& { background-color: var(--tier-2-gold); }
    .tier-3& { background-color: var(--tier-3-red); }
    .tier-4& { background-color: #000; } /* ✅ Black progress bar can reach 100% */
    .tier-white& { background-color: var(--mutedwhite); }
    z-index: 2;
    position: relative;
  }
`;

const TierRow = styled(Row)`
  &.vip {
    .col {
      flex: 0 0 18.75%;
      .tier-holder {
        font-size: 0.9rem;
      }
      &.active {
        flex: 0 0 25%;
        .tier-holder {
          /* font-size: 1rem; */
        }
      }
    }
  }
  .col {
    padding: 0;
    .tier-holder {
      padding: 0.35rem 0;
      text-align: center;
      font-weight: 700;
      color: var(--mutedwhite)!important;
      background-color: #0000002f;
      border-bottom: solid 6px transparent;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      span {
        position: relative;
        font-weight: normal;
        opacity: 0.5;

      }
      &.tier-1 { border-color: var(--tier-1-silver); }
      &.tier-2 { border-color: var(--tier-2-gold); }
      &.tier-3 { border-color: var(--tier-3-red); }
      &.tier-4 { border-color: #2b2929; }
      &.tier-white { border-color: var(--white); }
    }
    &.active {
      .tier-holder {
        box-shadow: inset 0px -5px 6px 0px rgba(0,0,0,0.25);
        color: var(--white)!important;
        span {
          font-weight: 700;
          opacity: 1;
          svg {
            opacity: 0.5;
            &:hover {
              opacity: 1;
            }
          }
        }
        &.tier-1 { background: var(--tier-1-silver); }
        &.tier-2 { background: var(--tier-2-gold); }
        &.tier-3 { background: var(--tier-3-red); }
        &.tier-4 { background: var(--tier-4-black); }
        &.tier-white { background: var(--white); color: var(--tier-white-highlight)!important; }
      }
    }
  }
`;

const InfoIconTrigger = styled.span`
  padding-bottom: 2px;
`;

export default StatusBanner;
