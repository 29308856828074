import styled from "styled-components";
import Hero from "src/components/Hero/Hero";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import TournamentDropDownText from "src/pages/poker/tournaments/TournamentDropDownText";
import GoogleFormSubmitter from "src/components/Form/GoogleFormSubmitter";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import { useHomepage } from "src/context/homepage/homepage.provider";
import EventsTable from "src/pages/poker/EventsTable";
import TitleSection from "src/components/TitleSection/TitleSection";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import { FaCircle, FaTrophy, FaUser } from "react-icons/fa";
import EventFinished from "src/components/EventFinished";

const WsopcPlaygroundSpring2025ColossusGiveaway = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const promoSourceRef = location.pathname.slice(-1);
  const { tournamentsData, tournamentDataFn } = useHomepage();
  useEffect(() => {
    tournamentDataFn(
      "2025-03-24",
      "2025-04-09",
      "wsop-c-playground-spring-2025",
      i18n.language
    );
  }, []);

  return (
    <Div>
      <Hero
        bgImage="events/wsopc_playground_spring_2025/WSOP-FREE-ENTRY-LP-BANNER.jpg"
        image={`events/wsopc_playground_spring_2025/WSOP-FREE-ENTRY-v3-${i18n.language}.png`}
        showArrow
        arrowBounce
      >
        <container-div>
          <Announcement>
            <ReactMarkdown className="content-title">
              {t("poker.wsopcPlaygroundSpring2025ColossusGiveaway.content.title")}
            </ReactMarkdown>
          </Announcement>
        </container-div>
      </Hero>
      <EventFinished
        scheduleKeyName="wsopcPlaygroundSpring2025Giveaway"
        eventType="promotion"
      />
      <container-div>
        <Announcement>
          <ReactMarkdown>
            {t("poker.wsopcPlaygroundSpring2025ColossusGiveaway.content.text")}
          </ReactMarkdown>
        </Announcement>
        <Row
          xs={1}
          md={2}
          className="g-3"
        >
          <Col className="">
            <GoogleFormSubmitter
              formId="AKfycbx0em4SA396RN_38K4cawPICYEmkDihfbqwKQjjD30xuKJ6-QXb3ffdhb7zmWElcew"
              promoSourceRef={
                promoSourceRef === "a"
                  ? "Amazon"
                  : promoSourceRef === "q"
                    ? "Quantcast"
                    : ""
              }
            />
          </Col>
          <Col className="">
            <PlainContainer>
              <SubTitleSection className="mt-4" as="h2" subTitle={t("poker.wsopcPlaygroundSpring2025ColossusGiveaway.content.whyEnter.title")} />
              {t(
                "poker.wsopcPlaygroundSpring2025ColossusGiveaway.content.whyEnter.list",
                { returnObjects: true }
              ).map((item, index) => (
                <div className="list-item" key={index}>
                  <ResponsiveImage
                    src={index === 3 ? "svg/clock.svg" : "svg/Check.svg"}
                    alt="check-icon"
                  />
                  <ReactMarkdown key={index}>{item}</ReactMarkdown>
                </div>
              ))}
              <SubTitleSection as="h3"
                subTitle={t("poker.wsopcPlaygroundSpring2025ColossusGiveaway.content.hurryTitle")}
                className="pb-3"
              />
              <p className="hurry">
                <ResponsiveImage
                  src="svg/clock.svg"
                  alt="check-icon"
                  className="d-inline"
                />
                {t("poker.wsopcPlaygroundSpring2025ColossusGiveaway.content.hurryText")}
              </p>
              <SubTitleSection as="h2"
              className="pb-0"
                subTitle={t("poker.wsopcPlaygroundSpring2025ColossusGiveaway.content.termsAndConditions.title")}
                text={t("poker.wsopcPlaygroundSpring2025ColossusGiveaway.content.termsAndConditions.text")}
              />
              <p>
              <CircleContainer>
                <FaCircle className="circle" />
                <span className="number"><span>18</span></span>
              </CircleContainer>
                {t("poker.wsopcPlaygroundSpring2025ColossusGiveaway.content.termsAndConditions.18")}
              </p>
              <p>
              <CircleContainer>
                <FaCircle className="circle" />
                <FaUser />
              </CircleContainer>

                {t("poker.wsopcPlaygroundSpring2025ColossusGiveaway.content.termsAndConditions.oneEntry")}
              </p>
              <p>
              <CircleContainer>
                <FaCircle className="circle" />
                <FaTrophy />
              </CircleContainer>
                {t("poker.wsopcPlaygroundSpring2025ColossusGiveaway.content.termsAndConditions.announcementDate")}
              </p>

              <TournamentDropDownText
                dropdownText={{
                  label: t(
                    "poker.wsopcPlaygroundSpring2025ColossusGiveaway.content.fullTermsAndConditions.label"
                  ),
                  list: t(
                    "poker.wsopcPlaygroundSpring2025ColossusGiveaway.content.fullTermsAndConditions.list"
                  ),
                }}
              />
            </PlainContainer>
          </Col>
        </Row>
        <TitleSection
          className="mt-4 pb-1"
          title={t(
            "poker.wsopcPlaygroundSpring2025ColossusGiveaway.content.wsopcContent.title"
          )}
        />
        <ReactMarkdown className="mb-3 text-center">
          {t("poker.wsopcPlaygroundSpring2025ColossusGiveaway.content.wsopcContent.text")}
        </ReactMarkdown>
        <EventsTable events={tournamentsData} />
      </container-div>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: auto;
  color: var(--white);
  .hero_container {
    background-position: top center;
  }
  .hero_image_container {
    padding: 2rem;
  }
  .tournament-drop-down-text {
    button {
      max-width: fit-content;
      width: 100%;
      max-width: fit-content;
      height: auto;
      padding: 0.7rem;
    }
  }
  @media (max-width: 768px) {
    .tournament-drop-down-text {
      margin-bottom: 0;
      button {
        font-size: 14px;
        padding: 0.5rem;
        max-width: 100%;
      }
    }
  }
  .form-container {
    .subtitle-section {
      padding: 0;
    }
  }
`;

const PlainContainer = styled.div`
  margin: 1rem 0;
  .list-item {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 10px;
    img,
    svg {
      width: 24px;
      height: 24px;
    }
    p {
      margin: 0;
    }
  }
  h4 {
    margin-top: 20px;
  }
  @media (max-width: 768px) {
    margin: 0;
    h4 {
      font-size: 18px;
    }
  }
  p.hurry {
    display: inline-flex;
    font-size: 1.25rem;
    font-weight: 700;
    width: 100%;
    img {
      height: 1.5rem;
      padding-right: 0.5rem;
      position: relative;
      top: 0.15rem;
      width: auto;
    }
  }
  .tournament-drop-down-text {
    button {
      border-radius: 5px;
      padding: 0.8rem 3rem;
    }
    ul {
      padding-left: 1.4rem;
    }
    li {
      margin-bottom: 0.5rem;
    }
  }
`;

const CircleContainer = styled.div`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.8rem;
  position: relative;
  top: 0.5rem;

  .circle {
    font-size: 2rem;
    color: var(--mutedwhite);
  }

  .number, svg {
    position: absolute;
    font-weight: bold;
    color: black;
    font-size: 1rem;
    text-align: center;
    span {
      position: relative;
      left: 1px;
      top: 1px;
    }
  }
`;

const Announcement = styled.div`
  /* border-radius: 10px; */
  padding: 1rem;
  /* margin: 1rem 0; */
  text-align: center;

  .content-title {
    h2 {
      text-shadow: none!important;
      margin: 0;
      color: #d02129;
    }
    padding: 1rem 0.75rem;
    color: var(--red);
    background: var(--white);
    /* margin-bottom: 20px; */
  }
  p {
    font-size: 1.25rem;
    };
`;

export default WsopcPlaygroundSpring2025ColossusGiveaway;
