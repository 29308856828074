import React from "react";
import ReactMarkdown from "react-markdown";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import styled from "styled-components";

const TournamentHighlightedList = ({ highlightedList }) => {
  if (!highlightedList) return null;

  return (
    <>
      <HighlightedListWrapper icon={highlightedList?.icon}>
        {highlightedList?.list && (
          <>
            <ReactMarkdown>{highlightedList?.title}</ReactMarkdown>
            <ul>
              {highlightedList?.list.map((item, index) => (
                <li key={index}>
                  {highlightedList?.icon && (
                    <ResponsiveImage
                      src={highlightedList?.icon}
                      alt="check-icon"
                    />
                  )}
                  <ReactMarkdown>{item}</ReactMarkdown>
                </li>
              ))}
            </ul>
          </>
        )}
        {highlightedList?.text && (
          <ReactMarkdown>{highlightedList?.text}</ReactMarkdown>
        )}
        {highlightedList?.listTwo && (
          <>
            <ReactMarkdown>{highlightedList?.titleTwo}</ReactMarkdown>
            <ul>
              {highlightedList?.listTwo.map((item, index) => (
                <li key={index}>
                  <ReactMarkdown>{item}</ReactMarkdown>
                </li>
              ))}
            </ul>
          </>
        )}
        {highlightedList?.textTwo && (
          <ReactMarkdown>{highlightedList?.textTwo}</ReactMarkdown>
        )}
        {highlightedList?.listThree && (
          <>
            <ReactMarkdown>{highlightedList?.titleThree}</ReactMarkdown>
            <ul>
              {highlightedList?.listThree.map((item, index) => (
                <li key={index}>
                  <ReactMarkdown>{item}</ReactMarkdown>
                </li>
              ))}
            </ul>
          </>
        )}
      </HighlightedListWrapper>
    </>
  );
};
const HighlightedListWrapper = styled.div`
  text-align: left;
  padding: 1.5rem;
  background-color: var(--lightgrey);
  border-radius: 8px;
  margin: 1rem 0;
  color: var(--black);
  h3 {
    font-weight: bold;
    margin-bottom: 1rem;
  }
  ul {
    li {
      display: ${({ icon }) => (icon ? "flex" : "list-item")};
      gap: 5px;
      img {
        height: 24px;
        width: 24px;
      }
      p {
        margin-bottom: 5px;
      }
    }
  }
  @media (max-width: 768px) {
    padding-left: 1rem;
  }
`;
export default TournamentHighlightedList;
