import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import ReactMarkdown from "react-markdown";
import CoverageAndResultsBanner from "src/components/Poker/Tournaments/CoverageAndResultsBanner";
import EventsTable from "../EventsTable";
import LeaderboardTable from "../LeaderboardTable";
import { useTranslation } from "react-i18next";
import TournamentThreeColumnImages from "./TournamentThreeColumnImages";
import TournamentContentSection from "./TournamentContentSection";
import TournamentHighlightedList from "./TournamentHighlightedList";
import TournamentVideoSection from "./TournamentVideoSection";
import TournamentDropDownText from "./TournamentDropDownText";
import QualifiedLeaderboardTable from "../QualifiedLeaderboardTable";
import TournamentPrizePoolTable from "./TournamentPrizePoolTable";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import TournamentHotelCard from "./TournamentHotelCard";
import TournamentTravelDiscount from "./TournamentTravelDiscount";
import SeatsGuaranteedTable from "../SeatsGuarenteedTable";
import TournamentSubtabsContent from "./TournamentSubtabsContent";
import TournamentSectionalTabs from "src/components/SectionalTabs/TournamentSectionalTabs";
import { useHomepage } from "src/context/homepage/homepage.provider";
import TournamentEventContent from "./TournamentEventContent";
import TournamentDynamicTable from "./TournamentDynamicTable";
import TournamentImageSection from "./TournamentImageSection";
import TitleSection from "src/components/TitleSection/TitleSection";

const TournamentContent = ({
  tournamentContent,
  tournamentsData,
  leaderboardData,
  leaderboardTabs,
}) => {
  const { i18n, t } = useTranslation();
  const [filteredData, setFilteredData] = useState([]);
  const { tournamentsSubtabs } = useHomepage();
  const lng = i18n.language;
  useEffect(() => {
    const { satellite, guaranteeSeats, wsopc, allSatellite, eventName, prize } =
      tournamentContent?.eventInfo || {};

    let filteredData = tournamentsData;

    if (satellite) {
      filteredData = tournamentsData?.filter((event) => {
        const name = event.name_en?.toLowerCase() || "";
        return name.includes("satellite") && Number(event.price) >= 250;
      });
    } else if (allSatellite) {
      filteredData = tournamentsData?.filter((event) => {
        const name = event.name_en?.toLowerCase() || "";
        return name.includes("satellite");
      });
    } else if (wsopc) {
      filteredData = tournamentsData?.filter((event) => {
        const name = event.name_en?.toLowerCase() || "";
        return name.includes("wsop-c");
      });
    } else if (guaranteeSeats) {
      filteredData = tournamentsData?.filter((event) =>
        Array?.isArray(guaranteeSeats)
          ? guaranteeSeats?.includes(event?.guarantee_seats)
          : event?.guarantee_seats === guaranteeSeats
      );
    } else if (eventName) {
      filteredData = tournamentsData?.filter((event) => {
        const name = event.event_name || "";
        return name.includes(eventName);
      });
    } else if (prize) {
      filteredData = tournamentsData?.filter(
        (event) => event.guarantee_amount === prize
      );
    }

    setFilteredData(filteredData);
  }, [tournamentContent, tournamentsData]);
  return (
    <Div
      variant={tournamentContent?.content?.variant}
      className={tournamentContent?.content?.variant}
    >
      <container-div>
        {tournamentContent?.pokerNews && (
          <CoverageAndResultsBanner
            label={tournamentContent?.pokerOrgLogo ? t("poker.tournaments.pokerNewsBannerTwo") : t("poker.tournaments.pokerNewsBanner")}
            resultsLink={tournamentContent?.pokerNews}
            pokerNews={tournamentContent?.pokerNews}
            pokerOrgLogo={tournamentContent?.pokerOrgLogo}
          />
        )}
        {tournamentContent?.content?.coverageBanner?.link &&
          !tournamentContent?.content?.coverageBanner?.below && (
            <CoverageAndResultsBanner
              label={tournamentContent?.content?.coverageBanner.label}
              resultsLink={tournamentContent?.content?.coverageBanner.link}
            />
          )}
        {tournamentContent?.content?.image && (
          <ResponsiveImage
            src={tournamentContent?.content?.image}
            alt={tournamentContent?.hero?.imageAlt}
          />
        )}
        {tournamentContent?.content?.threeColumnImages?.images && (
          <TournamentThreeColumnImages
            images={tournamentContent.content.threeColumnImages.images}
          />
        )}
        {tournamentContent?.guaranteed && (
          <>
            {tournamentContent.guaranteed.over && (
              <TournamentContentSection
                title={tournamentContent.guaranteed.over}
                middleText={tournamentContent.guaranteed.overPrizePool}
                paragraphText={tournamentContent.guaranteed.text}
                isAmount={true}
                lng={lng}
              />
            )}

            {tournamentContent.guaranteed.mainEvent && (
              <>
                <divider-wpt />
                <TournamentContentSection
                  title={tournamentContent.guaranteed.mainEvent}
                  middleText={tournamentContent.guaranteed.mainEventPrizePool}
                  paragraphText={tournamentContent.guaranteed.text}
                  isAmount={true}
                  lng={lng}
                />
              </>
            )}
          </>
        )}

        {tournamentContent?.guaranteedDate && (
          <TournamentContentSection
            title={tournamentContent.guaranteedDate.weekDay}
            middleText={tournamentContent.guaranteedDate.date}
            paragraphText={tournamentContent.guaranteedDate.time}
            isAmount={false}
            lng={lng}
          />
        )}

        {tournamentContent?.content?.title ? (
          <TitleSection
            dark={tournamentContent?.content?.variant !== "dark" ? true : false}
            as={"h2"}
            title={tournamentContent?.content?.title}
            text={tournamentContent?.content?.text}
          />
        ) : (
          <ContentText>
            <ReactMarkdown>{tournamentContent?.content?.text}</ReactMarkdown>
          </ContentText>
        )}

        {tournamentContent?.content?.hotelInfo?.map((hotel, index) => (
          <TournamentHotelCard key={index} {...hotel} />
        ))}

        {tournamentContent?.content?.flight && (
          <TournamentTravelDiscount
            flight={tournamentContent?.content?.flight}
          />
        )}

        {tournamentContent?.content?.grid && (
          <GridContent text={tournamentContent?.content?.grid?.textSticker}>
            <ResponsiveImage
              src={tournamentContent?.content?.grid?.image}
              alt="check-icon"
            />
            <div>
              <ReactMarkdown>
                {tournamentContent?.content?.grid?.text}
              </ReactMarkdown>
              {tournamentContent?.content?.grid?.specialText && (
                <SpecialText>
                  <ReactMarkdown>
                    {tournamentContent?.content?.grid?.specialText?.left}
                  </ReactMarkdown>
                  <ReactMarkdown>
                    {tournamentContent?.content?.grid?.specialText?.right}
                  </ReactMarkdown>
                </SpecialText>
              )}
            </div>
          </GridContent>
        )}

        {/* Use the Event Content Section to display blocks with arrows */}
        {tournamentContent?.eventContent && (
          <TournamentEventContent data={tournamentContent?.eventContent} />
        )}

        {/* Use the HighlightedList component */}
        {tournamentContent?.content?.highlightedList && (
          <TournamentHighlightedList
            highlightedList={tournamentContent.content.highlightedList}
          />
        )}

        {tournamentContent?.content?.dynamicTableData && (
          <TournamentDynamicTable
            tableData={tournamentContent?.content?.dynamicTableData}
          />
        )}

        {tournamentContent?.content?.textTwo && (
          <ContentTextSmall>
            <ReactMarkdown>{tournamentContent?.content?.textTwo}</ReactMarkdown>
          </ContentTextSmall>
        )}

        {tournamentContent?.content?.dynamicTableDataTwo && (
          <TournamentDynamicTable
            tableData={tournamentContent?.content?.dynamicTableDataTwo}
          />
        )}

        {tournamentContent?.content?.centeredText && (
          <ContentCenteredText>
            <ReactMarkdown>
              {tournamentContent?.content?.centeredText}
            </ReactMarkdown>
          </ContentCenteredText>
        )}

        {/* Use the HighlightedListTwo component */}
        {tournamentContent?.content?.highlightedListTwo && (
          <TournamentHighlightedList
            highlightedList={tournamentContent.content.highlightedListTwo}
          />
        )}

        {tournamentContent?.content?.textThree && (
          <ContentTextSmall>
            <ReactMarkdown>
              {tournamentContent?.content?.textThree}
            </ReactMarkdown>
          </ContentTextSmall>
        )}

        {tournamentContent?.videoSection && (
          <TournamentVideoSection
            videoUrl={tournamentContent?.videoSection?.link}
            textContent={tournamentContent?.videoSection?.text}
          />
        )}

        {tournamentContent?.imageSection && (
          <TournamentImageSection
            imageUrl={tournamentContent?.imageSection?.image}
            imageAlt={tournamentContent?.imageSection?.imageAlt}
            textContent={tournamentContent?.imageSection?.text}
          />
        )}

        {tournamentContent?.content?.smallText && (
          <ReactMarkdown>{tournamentContent?.content?.smallText}</ReactMarkdown>
        )}

        {tournamentContent?.content?.dropdownText && (
          <TournamentDropDownText
            dropdownText={tournamentContent?.content?.dropdownText}
          />
        )}

        {tournamentContent?.prizePool && (
          <TournamentPrizePoolTable prizePool={tournamentContent?.prizePool} />
        )}

        {filteredData && <EventsTable events={filteredData} />}

        {
          leaderboardTabs &&
          Object.values(leaderboardTabs)?.some(
            (tab) => tab.link === location.pathname
          ) &&
          (tournamentContent?.qualified ? (
            <QualifiedLeaderboardTable
              leaderboardData={leaderboardData}
              leaderboardTabs={leaderboardTabs}
            />
          ) : (
            <LeaderboardTable
              leaderboardData={leaderboardData}
              leaderboardTabs={leaderboardTabs}
            />
          ))}
        {tournamentContent?.imageTextWrapper && (
          <ImageTextWrapper>
            <ResponsiveImage
              src={tournamentContent?.imageTextWrapper?.image}
              alt="text-image"
            />
            <ReactMarkdown>
              {tournamentContent?.imageTextWrapper?.text}
            </ReactMarkdown>
          </ImageTextWrapper>
        )}
        {tournamentContent?.content?.tableDataText && (
          <ReactMarkdown>
            {tournamentContent?.content?.tableDataText}
          </ReactMarkdown>
        )}
        {tournamentContent?.content?.tableData && (
          <SeatsGuaranteedTable
            tableData={tournamentContent?.content?.tableData}
          />
        )}
        {tournamentContent?.content?.tableDataTextTwo && (
          <ReactMarkdown>
            {tournamentContent?.content?.tableDataTextTwo}
          </ReactMarkdown>
        )}
        {tournamentContent?.content?.tableDataTwo && (
          <SeatsGuaranteedTable
            tableData={tournamentContent?.content?.tableDataTwo}
          />
        )}
        {tournamentContent?.content?.sectionalTabs && (
          <TournamentSectionalTabs
            subTabs={tournamentContent?.content?.sectionalTabs}
          />
        )}
        {tournamentContent?.content?.sectionalTabsContent && (
          <TournamentSubtabsContent
            tournamentContent={
              tournamentContent?.content?.sectionalTabsContent[
                `subTab${tournamentsSubtabs}`
              ]
            }
          />
        )}

        {tournamentContent?.content?.coverageBanner?.link &&
          tournamentContent?.content?.coverageBanner?.below && (
            <CoverageAndResultsBanner
              label={tournamentContent?.content?.coverageBanner.label}
              resultsLink={tournamentContent?.content?.coverageBanner.link}
            />
          )}

        {tournamentContent?.content?.bottomText && (
          <ReactMarkdown>
            {tournamentContent?.content?.bottomText}
          </ReactMarkdown>
        )}
      </container-div>
    </Div>
  );
};
const Div = styled.div`
  padding-top: 20px;
  background-color: ${(props) =>
    props.variant === "dark" ? "var(--lightblack)" : "#fff"};
  color: ${(props) => (props.variant === "dark" ? "#fff" : "#000")};
  .title-section {
    h2 {
      padding: 0;
      ${css({
        textWrap: "balance",
      })}
    }
    p {
      max-width: 960px;
      padding: 0;
    }
  }
`;
const ContentText = styled.div`
  p:first-child {
    padding: 0 20px 20px 20px;
    font-size: 18px;
    line-height: 26px;
    margin: 0px auto;
    max-width: 960px;
  }
`;
const ContentCenteredText = styled.div`
  text-align: center !important;
  p:first-child {
    padding: 0 20px 20px 20px;
    font-size: 18px;
    line-height: 26px;
    margin: 0px auto;
    max-width: 960px;
  }
`;
const ContentTextSmall = styled.div`
  text-align: center;
`;
const GridContent = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin: 20px 0;
  li {
    text-align: left;
  }
  img {
    max-width: 470px;
  }
  div {
    max-width: 470px;
    position: relative;
    overflow: hidden;
    &:after {
      content: "${({ text }) => text}";
      position: absolute;
      top: 42px;
      right: -110px;
      font-size: 55px;
      color: rgba(255, 255, 255, 0.8);
      text-transform: uppercase;
      transform: rotate(40deg);
      background: rgba(205, 0, 0, 0.8);
      width: 400px;
      text-align: center;
    }
  }
`;
const SpecialText = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row wrap;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  float: left;
  background: #2d2c34;
  border: solid 1px #cc222a;
  color: var(--white);
  text-align: left;
  padding: 0 15px;
  p:first-child {
    color: #cc222a;
    font-size: 60px;
    font-weight: 700;
    padding: 0px;
  }
`;
const ImageTextWrapper = styled.div`
  margin: 20px 0;
  width: 100%;
  margin: 0 auto 40px auto;
  position: relative;
  z-index: 1;

  img {
    width: 100%;
    min-height: 200px;
    object-fit: cover;
  }

  p {
    position: absolute;
    padding: 10px 30px;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    color: white;
    font-weight: normal;
    text-align: center;
  }
  @media (max-width: 768px) {
    p {
      padding: 15px 20px;
      font-size: 14px;
    }
  }
`;

export default TournamentContent;
