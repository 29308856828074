import { useReducer, useMemo, useContext, useEffect } from "react";
import { homepageReducer } from "./homepage.reducer";
import { homepageActionTypes as actions } from "./homepage.actions";
import { live_cash_games, tournaments } from "src/api/poker";
import { HomepageState } from "./homepage.state";
import { gaming_machine_jackpots, high_stakes_bbj, omaha_bbj, primary_bbj } from "src/api/jackpots";

const HOMEPAGE_STATE = {
  menuBarToggle: false,
  circleButton: 0,
  isReadMore: true,
  homeOfPlayground: false,
  homeOfPlaygroundCardImg: "surfAndTurf2024",
  isEnglish: true,
  darkTheme: "",
  pastEventsNav: new Date().getFullYear().toString(),
  pastEventsData: [],
  bbjData: {},
  omahaData: {},
  highstakesData: {},
  gamingMachineJackpotData: {},
  liveGamesData: [],
  tournamentsData: null,
  sectionCategory: "",
  sectionDescription: "",
  tournamentsDetails: null,
  tournamentsSubtabs: 1,
};

export const HomepageStateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(homepageReducer, HOMEPAGE_STATE);

  const value = useMemo(
    () => ({
      ...state,
      setMenuBarToggle: (value) => {
        dispatch({ type: actions.MENU_BAR_TOGGLE, payload: value });
      },
      circleButtonFn: (value) => {
        dispatch({ type: actions.CIRCLE_BTN, payload: value });
      },
      isReadMoreFn: () => {
        dispatch({ type: actions.READ_MORE });
      },
      homeOfPlaygroundFn: () => {
        dispatch({ type: actions.HOME_OF_PLAYGROUND });
      },
      homeOfPlaygroundCardImgFn: (value) => {
        dispatch({ type: actions.HOME_OF_PLAYGROUND_CART_IMG, payload: value });
      },
      darkThemeFn: (value) => {
        dispatch({ type: actions.DARK_THEME, payload: value });
      },
      pastEventsNavFn: (value) => {
        dispatch({ type: actions.PAST_EVENTS_NAV, payload: value });
      },
      pastEventsDataFn: (value) => {
        dispatch({ type: actions.PAST_EVENTS_DATA, payload: value });
      },
      bbjDataFn: () => {
        // Function to fetch BBJ data
        const fetchBbjData = async () => {
          try {
            const data = await primary_bbj();
            dispatch({ type: actions.BBJ_DATA, payload: data });
          } catch (error) {
            console.error("Error fetching primary BBJ data:", error);
          }
        };

        // Call fetchBbjData immediately on component mount
        fetchBbjData();

        // Fetch BBJ data every 20 minutes (1200000 ms)
        const intervalId = setInterval(fetchBbjData, 1200000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
      },
      omahaDataFn: () => {
        try {
          omaha_bbj()
            .then((data) => {
              dispatch({ type: actions.OMAHA_DATA, payload: data });
            })
            .catch((error) => {
              console.error("Error fetching Omaha BBJ data:", error);
            });
        } catch (error) {
          console.error(
            "Unexpected error occurred while fetching Omaha BBJ data:",
            error
          );
        }
      },
      highstakesDataFn: () => {
        try {
          high_stakes_bbj()
            .then((data) => {
              dispatch({ type: actions.HIGHSTAKES_DATA, payload: data });
            })
            .catch((error) => {
              console.error("Error fetching high-stakes BBJ data:", error);
            });
        } catch (error) {
          console.error(
            "Unexpected error occurred while fetching high-stakes BBJ data:",
            error
          );
        }
      },
      gamingMachineJackpotDataFn: () => {
        try {
          gaming_machine_jackpots()
            .then((data) => {
              dispatch({
                type: actions.GAMING_MACHINE_JACKPOT_DATA,
                payload: data,
              });
            })
            .catch((error) => {
              console.error(
                "Error fetching gaming machines jackpot data:",
                error
              );
            });
        } catch (error) {
          console.error(
            "Unexpected error occurred while fetching gaming machines jackpot data:",
            error
          );
        }
      },
      liveGamesDataFn: () => {
        try {
          live_cash_games()
            .then((data) => {
              dispatch({ type: actions.LIVE_GAMES_DATA, payload: data });
              setIsLoading(false);
              setHasError(false);
            })
            .catch((error) => {
              console.error("Error fetching live cash games data:", error);
            });
        } catch (error) {
          console.error(
            "Unexpected error occurred while fetching live cash games data:",
            error
          );
        }
      },
      tournamentDataFn: async (start_date, end_date, event_keyword, locale) => {
        try {
          // Function to fetch tournament data
          let data;
          if (start_date && end_date) {
            data = await tournaments(start_date, end_date, event_keyword);
          } else {
            data = null;
          }
          const formattedData = data?.map((event) => ({
            ...event,
            title: event[`name_${locale}`] || event.name_en,
            start: event.start_at,
            classNames: [`type_${event.type}`],
            event_number: /satellite/i.test(event.name_en)
              ? null
              : event.event_number,
          }));
          dispatch({ type: actions.TOURNAMENTS_DATA, payload: formattedData });
        } catch (error) {
          console.error("Error fetching tournament data:", error);
        }
      },
      tornamentsDetailsFn: (value) => {
        dispatch({ type: actions.TOURNAMENTS_DETAILS, payload: value });
      },
      tournamentsSubtabsFn: (value) => {
        dispatch({ type: actions.TOURNAMENTS_SUBTABS, payload: value });
      },
      sectionCategoryFn: (value) => {
        dispatch({ type: actions.SECTION_CATEGORY, payload: value });
      },
      sectionDescriptionFn: (value) => {
        dispatch({ type: actions.SECTION_DESCRIPTION, payload: value });
      },
    }),
    [state]
  );

  return (
    <HomepageState.Provider value={value}>{children}</HomepageState.Provider>
  );
};

export const useHomepage = () => useContext(HomepageState);
