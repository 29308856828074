import { useState, useEffect } from "react";
import styled from "styled-components/macro";
import Hero from "../Hero/Hero";
import Button from "../Button/Button";
import PokerJackpotCard from "./PokerJackpotCard";
import SubTitleSection from "../SubTitleSection/SubTitleSection";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import {
  formatAmount,
  formatDate,
  toUrlFriendlyString,
} from "../../utils/formatters";
import { useHomepage } from "src/context/homepage/homepage.provider";

const BbjHero = ({ compact }) => {
  const { i18n, t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const { bbjData, bbjDataFn } = useHomepage();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (isLoading) {
        setIsLoading(false);
      }
    }, 4000); // 4 seconds timeout

    return () => clearTimeout(timeoutId);
  }, [isLoading]);

  useEffect(() => {
    if (bbjData && Object.keys(bbjData).length > 0) {
      setIsLoading(false);
    } else {
      bbjDataFn();
    }
  }, [bbjData]);

  const isBbjDataAvailable = bbjData && Object.keys(bbjData).length > 0;

  if (!isBbjDataAvailable && !isLoading) {
    console.log("No BBJ data");
  }

  const title = compact ? "" : `# ${t("poker.bbj.title")} \n\n`; // Use h1 title only if not compact

  const heroProps = {
    mainHeroText: isLoading
      ? `${title}${t("poker.bbj.loadingData")}`
      : isBbjDataAvailable
        ? `${title}### ${formatAmount(bbjData.amount, i18n.language)}`
        : `${title}${t("poker.bbj.errorFetching")}`,
    bgImage: "promotions/bbj-background2.jpg",
    description: isLoading
      ? `${t("poker.bbj.minHand")} **${t("poker.bbj.loading")}** \n\n ${t("poker.bbj.lastHit")} **${t("poker.bbj.loading")}**` // Display loading text while fetching
      : isBbjDataAvailable
        ? `${t("poker.bbj.minHand")} **${t(`poker.bbj.${bbjData.min_qualifying_hand}`)}** \n\n ${t("poker.bbj.lastHit")} **${formatDate(bbjData.last_hit_at, i18n.language)}**`
        : `${t("poker.bbj.minHand")} **--** \n\n ${t("poker.bbj.lastHit")} **--**`, // Display "--" if data fetching fails or no data available
    descriptionBgColor: "#0000004d",
    overlayVideoSrc: "/videos/bbj-overlay.mp4",
  };

  const learnMoreButton = {
    label: t("poker.bbj.learnMore"),
    href: t("routes.badBeatJackpot"),
    size: "medium",
    width: "xwide",
    specialHover: true,
  };

  const rulesButton = {
    label: t("poker.badBeatJackpot.readRules"),
    href:
      t("routes.badBeatJackpotRules") +
      "#" +
      toUrlFriendlyString(t("pokerRules.badBeatJackpot")),
    size: "medium",
    width: "xwide",
    specialHover: true,
  };

  return (
    <BbjHeroWrapper>
      {compact ? (
        <>
          <container-wide>
            <SubTitleSection subTitle={t("poker.bbj.title")} />
          </container-wide>
          <Hero {...heroProps} className="compact-bbj-hero">
            <Button {...learnMoreButton} />
          </Hero>
        </>
      ) : (
        <Hero {...heroProps}>
          <container-fluid>
            <h2>{t("poker.badBeatJackpot.jackpotWinMessage")}</h2>
            <Row xs={1} md={2} lg={4} className="mt-2 g-4 mb-0">
              <PokerJackpotCard
                title={t("poker.badBeatJackpot.jackpotWinner")}
                text={t("poker.badBeatJackpot.loseHandWin")}
                amount={formatAmount(bbjData?.winner_share, i18n.language)}
              />
              <PokerJackpotCard
                title={t("poker.badBeatJackpot.winnerOfHand")}
                text={t("poker.badBeatJackpot.giveBadBeatWin")}
                amount={formatAmount(bbjData?.hand_winner_share, i18n.language)}
              />
              <PokerJackpotCard
                title={t("poker.badBeatJackpot.tableShare")}
                text={t("poker.badBeatJackpot.beSignedAtBbjTableWin")}
                amount={formatAmount(bbjData?.table_share, i18n.language)}
              />
              <PokerJackpotCard
                title={t("poker.badBeatJackpot.roomShare")}
                text={t("poker.badBeatJackpot.beSignedAtOtherNlheTableWin")}
                amount={formatAmount(bbjData?.room_share, i18n.language)}
              />
            </Row>
          </container-fluid>
          <Row className="justify-content-md-center">
            <Col lg={8}>
              <p className="small mt-4">
                {t("poker.badBeatJackpot.tableShareInfo")}
              </p>
              <p className="small">{t("poker.badBeatJackpot.roomShareInfo")}</p>
            </Col>
          </Row>
          <Button {...rulesButton} />
        </Hero>
      )}
    </BbjHeroWrapper>
  );
};

const BbjHeroWrapper = styled.div`
  .hero_container {
    padding-top: 0;
    position: relative;
    &.compact-bbj-hero {
      margin-top: 0;
      .main-hero-text {
        margin: 2rem 0;
      }
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: inherit;
      filter: brightness(0.7);
      z-index: 1;
    }
  }
  .main-hero-text {
    margin: 9rem 0 3rem;
    text-align: center;
    @media (max-width: 991.8px) {
      margin-top: 3rem;
    }
    h1,
    h3 {
      margin: 10px 0;
      padding: 0;
    }
    h1 {
      font-size: clamp(0.1rem, 7vw, 4rem);
      font-weight: 700;
      color: var(--white);
      text-shadow: 1px 0px 10px var(--black);
    }
    h3 {
      color: var(--mustard);
      font-weight: 900;
      font-size: clamp(1rem, 16vw, 8rem);
      text-shadow: 1px 0px 10px var(--black);
      @media (min-width: 991.8px) {
        font-size: clamp(1rem, 16vw, 11rem);
      }
    }
    p {
      color: var(--white);
      max-width: 800px;
      padding: 0 1rem;
    }
  }
  .hero_text_container {
    p {
      font-size: 1.1rem;
      font-weight: 600;
      margin-bottom: 0.2rem;
      strong {
        font-weight: 600;
        color: var(--mustard);
        white-space: nowrap;
        @media (max-width: 450px) {
          display: block;
        }
      }
      &.small {
        font-size: 0.85rem;
        font-weight: 400;
        margin-bottom: 0.5rem;
      }
    }
    h3 {
      margin-top: 2rem;
      font-weight: 700;
    }
  }
`;

export default BbjHero;
