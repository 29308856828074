import React from "react";
import { Nav, Dropdown } from "react-bootstrap";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";

const StyledNav = styled(Nav).withConfig({
  shouldForwardProp: (prop) =>
    ![
      "bgColor",
      "activeBgColor",
      "textColor",
      "dropDownBgColor",
      "dropdownActiveTextColor",
      "borderColor",
    ].includes(prop),
})`
  .nav-link,
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    padding: 0.8rem 2rem;
    background-color: ${(props) =>
      props.bgColor || "var(--mutedred)"} !important;
    color: ${(props) => props.textColor || "#fff"};
    border-radius: 0;
    transition: all 0.3s ease-in-out;
    border: 0.5px solid ${(props) => props.borderColor || "#a5a3a3"};
    border-top: none;
    background-color: rgba(0, 0, 0, 0.5);
    &:hover,
    &.active-hash,
    &:focus {
      background-color: ${(props) =>
        props.activeBgColor || "var(--red)"} !important;
      color: ${(props) => props.dropdownActiveTextColor || "#fff"};
    }
  }
  .nav-item:last-child .nav-link {
    border-right: none;
  }
  .nav-item:first-child .nav-link {
    border-left: none;
  }

  .nav-item.dropdown .dropdown-menu {
    background-color: ${(props) =>
      props.dropDownBgColor || "var(--lightblack)"};
    padding: 0.5rem;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    .dropdown-item {
      color: ${(props) => props.textColor || "#fff"};
      border-radius: 4px;
      transition: background 0.2s ease-in-out;
      &:hover,
      &.active-hash,
      &:focus {
        background-color: ${(props) => props.activeBgColor || "var(--red)"};
        color: ${(props) => props.dropdownActiveTextColor || "#fff"};
      }
    }
  }
  @media (max-width: 768px) {
    flex-direction: column;
    /* padding: 1rem; */
    .nav-link,
    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
      border-left: none;
      border-right: none;
    }

    .nav-item:last-child .nav-link,
    .nav-pills .nav-item:last-child .nav-link.active,
    .nav-pills .show > .nav-item:last-child .nav-link {
    }
  }
`;

const NavTabs = ({ tabLinks, ...props }) => {
  const location = useLocation();

  const isActive = (link) => location.pathname === link;

  const renderTabs = (links) => {
    return links.map((tab, index) => {
      if (tab.type === "dropdown" && tab.options) {
        const isDropdownActive = tab.options.some((option) => isActive(option.link));

        return (
          <Dropdown as={Nav.Item} key={index}>
            <Dropdown.Toggle
              as={Nav.Link}
              className={`dropdown-toggle ${isDropdownActive ? "active-hash" : ""}`}
            >
              {tab.label}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {tab.options.map((option, idx) => (
                <Dropdown.Item
                  as={NavLink}
                  to={option.link}
                  key={idx}
                  className={isActive(option.link) ? "active-hash" : ""}
                >
                  {option.label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        );
      } else {
        return (
          <Nav.Item key={index} className="nav-item">
            <NavLink
              className={`nav-link ${isActive(tab.link) ? "active-hash" : ""}`}
              to={tab.link}
            >
              {tab.label}
            </NavLink>
          </Nav.Item>
        );
      }
    });
  };

  return (
    <StyledNav fill variant="pills" className="nav-fill" {...props}>
      {renderTabs(tabLinks)}
    </StyledNav>
  );
};


export default NavTabs;
