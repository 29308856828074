import i18n from "src/middleware/i18n";
const BASE_API = process.env.REACT_APP_BASE_URL;

export const privateEvents = async (formData) => {
  try {
    const headers = {
      Accept: "application/json",
      "Content-Language": i18n.language,
    };

    const response = await fetch(`${BASE_API}/private_events`, {
      method: "POST",
      body: formData,
      headers: headers,
    });

    if (response.ok) {
      return { success: true };
    } else {
      const errorText = await response.json();
      console.error(`Form submission failed: ${errorText}`);
      return { success: false, error: errorMessage };
    }
  } catch (error) {
    console.error(`Error during form submission: ${error.message}`);
    return { success: false, error: error.message };
  }
};