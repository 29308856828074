import React from "react";
import styled from "styled-components";
import { formatAmount } from "src/utils/formatters";

const TournamentContentSection = ({
  title,
  middleText,
  paragraphText,
  isAmount,
  lng,
}) => {
  return (
    <ContentHeader>
      <h2>{title}</h2>
      <span>{isAmount ? formatAmount(middleText, lng) : middleText}</span>
      <p>{paragraphText}</p>
    </ContentHeader>
  );
};

const ContentHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px 0;

  h2 {
    font-size: 1.5rem;
    text-transform: uppercase;
    line-height: 1.25rem;
    margin: 0;
  }

  span {
    font-weight: 700;
    font-size: 5rem;
    line-height: 5.625rem;
    color: #ff004a;
    text-transform: uppercase;
  }

  p {
    border: 0.125rem solid #000;
    font-weight: 600;
    padding: 0.3125rem 0.3125rem 0.3125rem 1.3125rem;
    max-width: 25rem;
    margin: 0 auto;
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 0.3125rem;
    color: #000;
    line-height: 1.875rem;
  }

  @media (max-width: 768px) {
    h2 {
      font-size: 1.25rem;
      line-height: 1.125rem;
    }

    span {
      font-size: 4rem;
      line-height: 4.5rem;
    }

    p {
      font-size: 1.25rem;
      line-height: 1.5rem;
      padding: 0.25rem 0.25rem 0.25rem 1rem;
      max-width: 20rem;
      letter-spacing: 0.25rem;
    }
  }
`;

export default TournamentContentSection;
