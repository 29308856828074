import React from "react";
import styled, { css } from "styled-components";
import ReactMarkdown from "react-markdown";

import { useTranslation } from "react-i18next";
import TitleSection from "src/components/TitleSection/TitleSection";
import { Row, Col } from "react-bootstrap";
import Hero from "src/components/Hero/Hero";
import EventFinished from "src/components/EventFinished";

const MainEventMaynia2025 = () => {
  const { t } = useTranslation();

  return (
    <MainDiv>
      <EventFinished
        scheduleKeyName="mainEventMaynia2025"
        eventType="promotion"
      />
      <Hero
        arrowBounce="true"
        bgImage="promotions/poker/main-event-maynia-2025/MY_lp_header_BG.jpg"
        image="promotions/poker/main-event-maynia-2025/My_carousel_logo-v2.png"
        imageAlt={t("poker.mainEventMaynia2025.title")}
      />
      <container-div>
        <TitleSection
          title={t("poker.mainEventMaynia2025.title")}
          text={t("poker.mainEventMaynia2025.qualifyDescription")}
          dark
        />

        <ReactMarkdown>
          {t("poker.mainEventMaynia2025.description1")}
        </ReactMarkdown>
        <ReactMarkdown>
          {t("poker.mainEventMaynia2025.description2")}
        </ReactMarkdown>

        <TitleSection
          title={t("poker.mainEventMaynia2025.playgroundQualifyHeader")}
          as="h2"
          dark
          className=""
        />

        <Row xs={1} md={2} className="g-2 mb-4">
          <Col>
            <StepContainer>
              <TitleSection
                title={t("poker.mainEventMaynia2025.step1.title")}
                as="h4"
                borderColor="#fff"
                className="py-0"
              />
              <ReactMarkdown>
                {t("poker.mainEventMaynia2025.step1.details")}
              </ReactMarkdown>
              <ReactMarkdown>
                {t("poker.mainEventMaynia2025.step1.advance")}
              </ReactMarkdown>
            </StepContainer>
          </Col>
          <Col>
            <StepContainer>
              <TitleSection
                title={t("poker.mainEventMaynia2025.step2.title")}
                as="h4"
                borderColor="#fff"
                className="py-0"
              />
              <ReactMarkdown>
                {t("poker.mainEventMaynia2025.step2.details")}
              </ReactMarkdown>
              <ReactMarkdown>
                {t("poker.mainEventMaynia2025.step2.win")}
              </ReactMarkdown>
            </StepContainer>
          </Col>
        </Row>
      </container-div>
    </MainDiv>
  );
};

const StepContainer = styled(Col)`
  background-color: var(--lightblack);
  border-radius: 15px;
  padding: 15px;
  color: var(--white);
  text-align: center;
  h4,
  h5 {
    font-weight: 700;
  }
  .title-section {
    span {
      margin-bottom: 10px;
    }
    p {
      font-weight: 700;
      margin-bottom: 0;
    }
  }
`;

const MainDiv = styled.div`
  background: var(--white);
  text-align: center;
  .hero_image_container img {
    padding: 48px;
  }
  .hero_text_container {
    display: none;
  }
  .title-section {
    padding-bottom: 0;
    ${css({
      textWrap: "balance",
    })}
    h1, h2 {
      padding: 0;
    }
    p {
      max-width: 960px;
      padding: 0;
    }
  }
`;

export default MainEventMaynia2025;
