import React from "react";
import styled from "styled-components";
import { darkCardStyle } from "src/GlobalStyle";
import { useTranslation } from "react-i18next";
import { formatAmount } from "src/utils/formatters";
import { getPointsLeft } from "src/utils/accountUtils";
import StatusBanner from "src/components/Account/StatusBanner";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import BalanceBanner from "src/components/Account/BalanceBanner";
import { Tooltip, OverlayTrigger, Table } from "react-bootstrap";
import { MdInfo } from "react-icons/md";


const StatusCard = ({ accountInfo = {} }) => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const accountData = accountInfo?.data || {};
  const { current_tier,
          // balance_egd,
          // email,
          // is_banned,
          // last_played_date,
          // last_update,
          // name,
          // player_number,
          previous_tier,
          previous_tier_balance,
          redeemable_balance,
          // registration,
          // source,
          tier_balance,
          tier_from_current_balance,
          is_vip
        } = accountData;

  const pointsLeftToNextTier = getPointsLeft(tier_balance, current_tier, current_tier + 1);
  const pointsLeftToKeepTier = getPointsLeft(tier_balance, current_tier, current_tier);

  // **🔹 Choose the correct translation key based on tier**
  const tierPointsKey = {
    1: "myAccount.pointsLeftSilver",
    2: "myAccount.pointsLeftGold",
    3: "myAccount.pointsLeftRed",
    4: "myAccount.pointsLeftBlack",
  }[current_tier] || "myAccount.pointsLeftSilver"; // Default to Silver

  const renderStatusTooltip = (props) => (
    <StatusTooltip id="balances-tooltip" className="balances-tooltip " {...props}>
      <SubTitleSection
        as={"h4"}
        subTitle={`${t("myAccount.yourStatus")} ${t(`myAccount.tier-${current_tier}`)}`}
        className="pt-0 pb-3 d-none"
      />
      <BalancesTable condensed variant="dark" className="mt-0 text-center d-none">
        <thead>
          <tr>
            <th>{t("myAccount.year")}</th>
            <th className="text-end">{t("myAccount.points")}</th>
            <th>{t("myAccount.status")}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{new Date().getFullYear()}</td>
            <td className="text-end">{formatAmount(tier_balance, lang, false)}</td>
            <td>{t(`myAccount.tier-${tier_from_current_balance}`)}</td>
          </tr>
          { previous_tier && previous_tier_balance && (
            <tr>
              <td>{new Date().getFullYear() - 1}</td>
              <td className="text-end">{formatAmount(previous_tier_balance, lang, false)}</td>
              <td>{t(`myAccount.tier-${previous_tier}`)}</td>
            </tr>
          )}
        </tbody>
      </BalancesTable>
      <small>
        {t("myAccount.statusDesc")}
      </small>
    </StatusTooltip>
  );
  const renderVipTooltip = (props) => (
    <StatusTooltip className="vip-tooltip " {...props}>
      <small>
        {t("myAccount.whiteTooltip")}
      </small>
    </StatusTooltip>
  );

  return (
    <YourRewardsDiv className="my-3 p-4">
      <SubTitleSection subTitle={t("myAccount.rewardsAndStatus")} className="pt-0" />
      <SubTitleSection as={"h3"} subTitle={t("myAccount.yourRewards")} text={`${t("myAccount.yourRewardsDesc")} \n\n[${t("myAccount.rewardsMoreInfo")}](${t("routes.rewards")})`} />

      <BalanceBanner balance={redeemable_balance} currentTier={current_tier} isVip={is_vip} />

      <SubTitleSection
        as={"h3"}
        subTitle={
          <>
            {t("myAccount.yourStatus")} {is_vip ? t("myAccount.white") : t(`myAccount.tier-${current_tier}`)}

            <OverlayTrigger
              placement="top"
              overlay={is_vip ? renderVipTooltip : renderStatusTooltip}
            >
              <InfoIconTrigger className="overlay-trigger bump-up more ms-2">
                <MdInfo className={`tier-${current_tier}`} />
              </InfoIconTrigger>
            </OverlayTrigger>
          </>
        }
        text={
          is_vip ? t("myAccount.whiteDesc") :
            tier_from_current_balance < current_tier
              ? t("myAccount.keepPreviousTier", {
                  currentTier: t(`myAccount.tier-${current_tier}`),
                  pointsLeftToKeepTier: formatAmount(pointsLeftToKeepTier, lang, false)
                })
              : t(tierPointsKey, {
                  pointsLeftToNextTier: formatAmount(pointsLeftToNextTier, lang, false)
                })
        }
      />

      <StatusBanner
        currentTier={current_tier}
        tierBalance={tier_balance}
        tierFromCurrentBalance={tier_from_current_balance}
        previousTier={previous_tier}
        previousTierBalance={previous_tier_balance}
        isVip={is_vip}
      />
    </YourRewardsDiv>
  );
};

const YourRewardsDiv = styled.div`
  ${darkCardStyle}
  padding: 2rem;
  color: var(--mutedwhite);
  .subtitle-section {
    padding-bottom: 1rem;
    p {
      margin-bottom: 0;
    }
  }
`;

const StatusTooltip = styled(Tooltip)`
  opacity: 1!important;
  .tooltip-inner {
    text-align: left;
  }
`;

const BalancesTable = styled(Table)`
  td, th {
    padding: 0.25rem;
    font-size: 0.9rem;
  }
`;

const InfoIconTrigger = styled.span`
  padding-bottom: 2px;
  svg {
    opacity: 0.5;
    font-size: 1rem;
    &:hover {
      opacity: 1;
    }
  }
`;

export default StatusCard;
