import { useState } from "react";
import styled from "styled-components";
import { FaBuilding, FaUsers } from "react-icons/fa";
import Hero from "../components/Hero/Hero";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "../components/ImageWrapper/ResponsiveImage";
import useResponsiveImageUrl from "../utils/useResponsiveImageUrl";
import ReactMarkdown from "react-markdown";
import MarkdownWithRouter from "src/components/MarkdownWithRouter";

const AboutUs = () => {
  const { t } = useTranslation();
  const [more, setMore] = useState("");
  const handleMoreFn = (action) => {
    more !== action ? setMore(action) : setMore("");
  };
  const responsiveBgImage = useResponsiveImageUrl("about/about-background.jpg");

  return (
    <AboutUsDiv>
      <Hero
        bgColor=""
        bgImage={"about/about-us-hero-bg_v2.jpg"}
        buttonLink=""
        buttonText=""
        description=""
        descriptionBgColor=""
        image=""
        imageAlt=""
        mainHeroText={t("about.title_h1")}
        title=""
      >
        <IconSection xs={2} lg={4} className="g-4">
          <Col>
            <i>
              <ResponsiveImage src="about/poker-icon.png" alt="poker-icon" />
            </i>
            <p>{t("about.nav-one")}</p>
          </Col>
          <Col>
            <i>
              <ResponsiveImage
                src="about/egd-icon.png"
                alt="Gaming machine icon"
              />
            </i>
            <p>{t("about.nav-two")}</p>
          </Col>
          <Col>
            <i>
              <FaBuilding />
            </i>
            <p>{t("about.nav-three")}</p>
          </Col>
          <Col>
            <i>
              <FaUsers />
            </i>
            <p>{t("about.nav-four")}</p>
          </Col>
        </IconSection>
      </Hero>
      <MainContentDiv bgImage={responsiveBgImage}>
        <container-div>
          <div>
            <ReactMarkdown>{t("about.main-content-1")}</ReactMarkdown>
            <ReactMarkdown>{t("about.main-content-2")}</ReactMarkdown>
            <ReactMarkdown>{t("about.main-content-3")}</ReactMarkdown>
            <ReactMarkdown>{t("about.main-content-4")}</ReactMarkdown>
            <ReactMarkdown>{t("about.main-content-5")}</ReactMarkdown>
            <MarkdownWithRouter>{t("about.main-content-6")}</MarkdownWithRouter>
          </div>
        </container-div>
      </MainContentDiv>
    </AboutUsDiv>
  );
};

const IconSection = styled(Row)`
  .col {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
  }
  i {
    background: var(--red);
    color: var(--white);
    height: 60px;
    width: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    & > svg {
      font-size: 25px;
      margin: 0;
      padding: 0;
    }
    & > img {
      width: 32px;
    }
  }
  p {
    max-width: 200px;
    color: var(--white);
    margin: 1rem auto;
    font-weight: 500;
    font-size: 1rem;
  }
`;

const AboutUsDiv = styled.div`
  width: 100%;
  height: auto;
  min-height: 100vh;
  background: var(--black);
`;

const MainContentDiv = styled.div`
  height: auto;
  width: 100%;
  color: var(--white);
  background: #000 url(${(props) => props.bgImage}) 50% 50% fixed no-repeat;
  background-size: cover;
  div {
    padding: 1rem;
    background: rgba(0, 0, 0, 0.5);
    margin: 3rem auto;
    p {
      padding: 0 0 1rem;
    }
  }
`;

export default AboutUs;
