import { formatAmount } from "src/utils/formatters";


/**
 * Defines the tier boundaries for progress calculation.
 * Each tier is assigned 25% of the total progress bar.
 */
export const TIERS = [0, 2500, 30000, 300000];

/**
 * Retrieves the next tier boundary based on the current status.
 * @param {number} currentTier - The current tier (1-4).
 * @returns {number|null} The next tier's boundary, or null if at the highest tier.
 */
const getNextTierBoundary = (currentTier) => {
  return currentTier < TIERS.length ? TIERS[currentTier] : null;
};

/**
 * Calculates the number of points needed to reach a specific tier boundary.
 * By default, calculates points left to reach the next tier (toTier = currentTier + 1).
 * Use toTier = currentTier to calculate points left to keep the current tier.
 *
 * @param {number} totalEarned - The current points (e.g. tier_balance).
 * @param {number} currentTier - The current tier (1-4).
 * @param {number} [toTier=currentTier + 1] - The target tier for which to calculate the boundary.
 * @returns {number} Points needed to reach the specified tier boundary (0 if already reached or exceeded).
 */
export const getPointsLeft = (totalEarned, currentTier, toTier = currentTier + 1) => {
  const boundaryIndex = toTier - 1; // Because TIERS is 0-indexed
  const boundary = TIERS[boundaryIndex];
  return boundary ? Math.max(boundary - totalEarned, 0) : 0;
};

/**
 * Calculates the total progress percentage across all tiers.
 * - Each tier contributes 25% to the total progress bar.
 * - Progress within a tier is scaled to fit within that 25%.
 * - If the user is in Tier 4, returns 100%.
 *
 * @param {number} totalEarned - The total earned points.
 * @param {number} currentTier - The current tier (1-4).
 * @returns {number} Progress percentage for the full progress bar (0-100).
 */
export const getTotalProgressPercentage = (totalEarned, currentTier) => {
  if (currentTier === 4) {
    return 100; // Tier 4 is always 100%
  }

  const currentTierBoundary = TIERS[currentTier - 1]; // Start of current tier
  const nextTierBoundary = getNextTierBoundary(currentTier);

  const tierRange = nextTierBoundary - currentTierBoundary;
  const progressInTier = (totalEarned - currentTierBoundary) / tierRange;

  // Distribute progress across total bar (each tier = 25%)
  return Math.min(progressInTier * 25 + (currentTier - 1) * 25, 100);
};


import i18n from "i18next"; // Ensure you import i18n for translations

/**
 * Formats Free Play credit names to a localized string.
 *
 * Converts:
 * - `"FP1 CAD$100.00 non-redeemable credit"` → `"$100.00 in Free Play"`
 * - `"FP1 CAD$250.00 non-redeemable credit"` → `"250.00$ en Crédits-jeu"`
 *
 * @param {string} name - The original Free Play name.
 * @param {string} locale - The locale (`"en"` for English, `"fr"` for French).
 * @returns {string} - The formatted name.
 */
export const formatFreePlayName = (name, locale = i18n.language || "en") => {
  const match = name.match(/FP1 CAD\$(\d+(\.\d{2})?) non-redeemable credit/i);

  if (!match) return name; // If no match, return the original string

  const amount = match[1]; // Extracted dollar amount

  return i18n.t("myAccount.freePlayFormat", { amount: formatAmount(amount, i18n.language, true, true) })
};
