import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Button from "../Button/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAccount } from "src/context/account/account.provider";
import ResponsiveImage from "../ImageWrapper/ResponsiveImage";
import Cookies from "js-cookie";

const Hero = () => {
  const { t } = useTranslation();
  const accessToken = Cookies.get("access_token");

  return (
    <>
      <HeroContainer>
        <container-div>
          <Row>
            <Col className="text-col" lg={5}>
              <h1>{t("rewards.section-1-title")}</h1>
              <p>{t("rewards.section-1-text")}</p>
              <Button
                bgColor="var(--red)"
                color="var(--white)"
                hoverBgColor="var(--black)"
                hoverColor="var(--white)"
                href={
                  accessToken ? t("routes.myAccount") : t("routes.register")
                }
                label={
                  accessToken
                    ? t("footer.myAccount")
                    : t("rewards.section-1-btn")
                }
                width="wide"
                size="large"
              />
            </Col>
            <Col lg={7}>
              <ResponsiveImage
                src={"home/rewards_cards.jpg"}
                alt="rewards-card"
              />
            </Col>
          </Row>
        </container-div>
      </HeroContainer>
    </>
  );
};

const HeroContainer = styled.div`
  width: 100%;
  background-color: var(--lightgrey);
  padding-top: 140px;
  padding-bottom: 50px;
  text-align: center;
  @media (min-width: 991px) {
    padding-top: 150px;
    text-align: left;
    a {
      margin-left: 0;
    }
  }
  .text-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h1 {
      font-size: 65px;
      font-weight: 900;
      margin: 0 0 1rem;
    }
    p {
      font-size: 22px;
    }
  }
  img {
    max-width: 100%;
    margin-top: 1rem;
    @media (min-width: 576px) {
      max-width: 90%;
    }
    @media (min-width: 991px) {
      max-width: 100%;
    }
  }
  @media (max-width: 768px) {
    .text-col {
      h1 {
        font-size: 32px;
      }
    }
  }
`;

export default Hero;
