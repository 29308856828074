// This config is used by
//  - HeaderNav.jsx to determine is links should be shown.
//    (there are also conditional statements in the footer and secondary nav)
//  - Promotions.jx to determine if a promo card should be shown.
//  - EvenetFinished banner component on expired event/promo pages.
// This allows us to define a publish schedule in advance and have these components show/hide.
// The schedule is based on the current date and time. EX: unpublish_at: "2025-03-19T16:42:00" // please use this example set the time if needed

const scheduleConfig = {
  // Entertainment
  musicalMonday: {
    publish_at: "2024-01-01",
    unpublish_at: "2021-09-06"
  },
  girlsNightOut: {
    publish_at: "2024-01-01",
    unpublish_at: "2024-12-11"
  },
  newYearsEve2025: {
    // Don't delete this key,
    // it is used in Cypress tests
    // schedule-promo-config.cy.js
    publish_at: "2024-11-01",
    unpublish_at: "2025-01-01",
  },
  valentines2025: {
    publish_at: "2025-01-24",
    unpublish_at: "2025-02-15"
  },
  bigGameBash2025: {
    publish_at: "2025-01-27",
    unpublish_at: "2025-02-10"
  },
  // Dining
  lobsterSpecial2025: {
    publish_at: "2025-02-25"
  },
  surfAndTurf2024: {
    publish_at: "2024-09-19"
  },
  playgroundBuffets: {
    publish_at: "2024-09-19"
  },
  get25PercentOff: {
    publish_at: "2024-09-19"
  },
  earlyBirdBreakfastSpecial: {
    publish_at: "2024-09-19"
  },
  classicAmericanBreakfast: {
    publish_at: "2024-09-19"
  },
  earlyBirdSpecial: {
    publish_at: "2024-09-19",
    unpublish_at: "2024-10-29"
  },
  // Poker
  mysteryHighHandOctober2024: {
    // EXAMPLE WITH TIME
    // REMEMBER TO PUT THE T
    publish_at: "2024-01-01",
    unpublish_at: "2021-11-29T15:16:00"

  },
  highHandDecember2024: {
    publish_at: "2024-11-22",
    unpublish_at: "2025-01-01"
  },
  highHandJanuary2025: {
    publish_at: "2024-11-22",
    unpublish_at: "2025-02-01"
  },
  highHandMarch2025: {
    publish_at: "2024-02-26",
    unpublish_at: "2025-04-01"
  },
  highHandApril2025: {
    publish_at: "2025-03-25",
    unpublish_at: "2025-05-01"
  },
  nationalPokerDay2025: {
    publish_at: "2025-03-25",
    unpublish_at: "2025-04-20"
  },
  anniversaryHighHandDec2024: {
    publish_at: "2024-11-22",
    unpublish_at: "2024-12-18"
  },
  irishOpen2025: {
    publish_at: "2024-12-18",
    unpublish_at: "2025-02-26"
  },
  mainEventMaynia2025: {
    publish_at: "2025-04-01",
    unpublish_at: "2025-06-09"
  },
  pokerTlb: {
    // Don't delete this key,
    // it is used in Cypress tests
    // schedule-promo-config.cy.js
    publish_at: "2024-09-19"
  },
  badBeatJackpot: {
    publish_at: "2024-09-19"
  },
  chaseTheAce2024: {
    publish_at: "2024-09-19",
    unpublish_at: "2024-12-20",
  },
  pokerNewYearsEve2025: {
    // Don't delete this key,
    // it is used in Cypress tests
    // schedule-promo-config.cy.js
    publish_at: "2024-11-01",
    unpublish_at: "2025-01-01",
  },
  worldCupOfCards2025: {
    publish_at: "2024-01-01",
    unpublish_at: "2025-02-03",
  },
  wsopcPlaygroundSpring2025: {
    publish_at: "2025-01-21",
    unpublish_at: "2025-04-09",
  },
  wsopcPlaygroundSpring2025Giveaway: {
    publish_at: "2025-01-21",
    unpublish_at: "2025-03-22",
  },
  titanTuesday2025: {
    publish_at: "2025-01-10",
  },
  springBreakPowerWeeks2025: {
    publish_at: "2025-01-28",
    unpublish_at: "2025-03-10",
  },
  highHandFebruary2025: {
    publish_at: "2025-01-30",
    unpublish_at: "2025-03-01"
  },
  moneyMakerTour2025: {
    publish_at: "2025-02-22",
    unpublish_at: "2025-05-21"
  },
  // EGD
  chaseTheAce2024: {
    publish_at: "2024-09-19",
    unpublish_at: "2024-12-20"
  },
  chineseNewYear2025: {
    publish_at: "2025-01-03",
    unpublish_at: "2025-02-17"
  },
  saintPatricksDay2025: {
    publish_at: "2025-03-03",
    unpublish_at: "2025-03-18"
  },
  spinAndSpoilMom2025: {
    publish_at: "2025-04-01",
    unpublish_at: "2025-04-21"
  },
  easterEggStravaganza2025: {
    publish_at: "2025-03-14",
    unpublish_at: "2025-04-21"
  },
  weeklyJackpotParty: {
    publish_at: "2024-09-19"
  },
  instaWin: {
    publish_at: "2024-09-19"
  },
  boomingWednesday: {
    publish_at: "2024-09-19"
  },
  optInAndWin: {
    publish_at: "2024-09-19"
  },
  followForFreePlay: {
    publish_at: "2024-09-19"
  },
  happyBirthday: {
    publish_at: "2024-09-19"
  },
  welcomeBackBonus: {
    publish_at: "2024-09-19"
  }
};

export default scheduleConfig;
