import styled from "styled-components";
import Hero from "src/components/Hero/Hero";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";

const LobsterSpecial2025 = () => {
  const { t, i18n } = useTranslation();

  return (
    <SurfAndTurfDiv>
      <Hero
        bgImage="promotions/dining/lobster-special/Header-2560x800px.jpg"
        buttonSettings={null}
        image={`promotions/dining/lobster-special/Logo-2400px-${i18n.language}.png`}
        darkenBg={0.5}
        description={t("diningPromos.lobsterSpecial2025.hero.title")}
        descriptionBgColor="#0000004d"
      >
        {/* <p>For just $19</p> */}
      </Hero>
      <MainContentDiv>
        <container-div>
          <Row className="g-4 mb-5" xs={1} md={2}>
            <Col>
              <SubTitleSection
                as={"h2"}
                subTitle={t("diningPromos.lobsterSpecial2025.content.subtitle")}
              />
              <ReactMarkdown className="desc">
                {t(
                  "diningPromos.lobsterSpecial2025.content.lobster.description"
                )}
              </ReactMarkdown>
              <ReactMarkdown>
                {t(
                  "diningPromos.lobsterSpecial2025.content.pgPlayersCard.description_elevate"
                )}
              </ReactMarkdown>
              <p>
                <strong>
                  {t(
                    "diningPromos.lobsterSpecial2025.content.pgPlayersCard.description_no_card"
                  )}
                  &nbsp;
                  <Link to={t("routes.register")}>
                    {t(
                      "diningPromos.lobsterSpecial2025.content.pgPlayersCard.description_sign_up"
                    )}
                  </Link>
                </strong>
              </p>
              <ReactMarkdown>
                {t(
                  "diningPromos.lobsterSpecial2025.content.pgPlayersCard.description_2"
                )}
              </ReactMarkdown>
            </Col>
            <Col className="pt-md-4">
              <ResponsiveImage
                src={`promotions/dining/lobster-special/Secondary-LP-Image-2400x1600-${i18n.language}.jpg`}
                alt={t("diningPromos.lobsterSpecial2025.hero.title")}
              />
            </Col>
          </Row>
        </container-div>
      </MainContentDiv>
      <container-div>
        <center-content>
          <p className="small">
            {t("diningPromos.lobsterSpecial2025.content.smallText")}
          </p>
        </center-content>
      </container-div>
    </SurfAndTurfDiv>
  );
};

const SurfAndTurfDiv = styled.div`
  width: 100%;
  height: auto;
  /* min-height: 100vh; */
  background: var(--lightblack);
  color: var(--white);
  padding-bottom: 1rem;
  .hero_image {
    padding: 2rem 2rem 4rem;
    @media (min-width: 768px) {
      padding: 3rem;
    }
    opacity: 0.9;
  }
  .small {
    text-align: center;
  }
`;

const MainContentDiv = styled.div`
  padding-bottom: 1rem;
  h2:after {
    border-color: #F44C00;
  }
  @media (min-width: 768px) {
    padding: 2rem 1rem 1rem;
  }
  container-div {
    background: rgba(0, 0, 0, 0.5);
  }
  /* @media (min-width: 992px) {
    padding: 3rem 0;
  } */
  height: auto;
  width: 100%;
  text-align: left;
  .p_1 strong {
    white-space: nowrap;
  }
  .desc strong {
    color: #F44C00;
  }
  p {
    margin-bottom: 2rem;
  }
`;

export default LobsterSpecial2025;
