import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { formatAmount } from "../../utils/formatters";

const BalanceBanner = ({ balance, currentTier, isVip }) => {
  const { t, i18n } = useTranslation();

  return (
    <BannerWrapper className={isVip ? "vip" : ""}>
      <div className="balance-content">
        <div className={`balance-title tier-${ isVip ? "white" : currentTier}`}>{t("myAccount.balance")}</div>
        <div className="balance-amount">{formatAmount(balance, i18n.language, false, true) || 0}</div>
        <div>{t("myAccount.points")}</div>
      </div>
    </BannerWrapper>
  );
};

const BannerWrapper = styled.div`
  text-align: center;
  margin: 10px 0;
  color: var(--white);
  text-align: left;
  &.vip {
    .balance-title {
      color: var(--black);
    }
    .balance-content {
      background-color: var(--white);
      color: var(--tier-white-highlight);
    }
  }
  .balance-content {
    border-radius: 3px;
    background-color: var(--black);
    text-align: center;
    overflow: hidden;
    padding: 15px;
    margin: 10px 0;
  }
  .balance-title {
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 2px;
    width: 100%;
    padding: 5px 0px;
    border-radius: 5px;
    &.tier-1 { background: var(--tier-1-silver); }
    &.tier-2 { background: var(--tier-2-gold); }
    &.tier-3 { background: var(--tier-3-red); }
    &.tier-4 { background: var(--tier-4-black); }
    &.tier-white { background: var(--tier-white-highlight); color: var(--white); }
  }
  .balance-amount {
    font-size: 30px;
    font-weight: 800;
    line-height: normal;
    text-align: center;
  }
  .balance-points {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 3px;
    line-height: 21px;
    color: #a7a7a7;
  }
`;

export default BalanceBanner;
