import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useErs } from "src/context/ers/ers.provider";

import TitleSection   from "../components/TitleSection/TitleSection";
import LiveGames      from "../components/Poker/LiveGames";
import Tournaments    from "../components/Poker/Tournaments";
import JoinTheClub    from "../components/HomePage/JoinTheClub";
import BbjHero        from "../components/Poker/BbjHero";
import HeroCarousel   from "../components/Carousel/HeroCarousel";

const Poker = () => {
  const { i18n, t } = useTranslation();

  const { data, fetchData } = useErs(); // Use the ERS hook
  const [error, setError] = useState(null); // Error state

    // Fetch the carousel data when the component mounts or language changes
    useEffect(() => {
      fetchData(`carousel-poker-${i18n.language}`)
      .then(() => {
        setError(null); // Clear error if successful
      })
      .catch((err) => {
        setError(true); // Set error to true on failure
        console.error("Error fetching carousel data:", err);
      });
    }, [fetchData, i18n.language]);

  return (
    <PokerDiv>
      <switcheroo-holder>
        <TitleSection title={t("poker.title")} text={t("poker.title-text")} className="pb-3" />
        <HeroCarousel
          carouselData={data[`carousel-poker-${i18n.language}`] && data[`carousel-poker-${i18n.language}`].length > 0
            ? data[`carousel-poker-${i18n.language}`]
            : [t('defaultHeroCarousel.loading', { returnObjects: true })]}
        />
      </switcheroo-holder>

      <BbjHero compact className="mt-3" />
      <LiveGames />
      <Tournaments />
      <JoinTheClub />
    </PokerDiv>
  );
};

const PokerDiv = styled.div`
  width: 100%;
  background: var(--black);
  position: relative;
`;

export default Poker;
