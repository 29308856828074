import styled from "styled-components";
import Hero from "src/components/Hero/Hero";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import EventFinished from "src/components/EventFinished";
import TitleSection from "src/components/TitleSection/TitleSection";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const SpinAndSpoilMom2025 = () => {
  const { t, i18n } = useTranslation();

  return (
    <Div>
      <Hero
        bgImage="promotions/gaming-machines/spin-and-spoil-mom-2025/mothers-day-hero-image-2400x800px.jpg"
        buttonSettings={null}
        image={`promotions/gaming-machines/spin-and-spoil-mom-2025/mothers-day-logo-2400x2400px-${i18n.language}.png`}
        title={t("spinAndSpoilMom2025.hero.title")}
        descriptionBgColor="#c23c4f"
      ></Hero>
      <EventFinished
        scheduleKeyName="spinAndSpoilMom2025"
        eventType="promotion"
      />
      <MainContentDiv
        bgImage={useResponsiveImageUrl(
          "promotions/gaming-machines/saint-patricks-day-2025/St-Patricks-Day-secondary-LP-image-2400x1600-en.jpg"
        )}
      >
        <container-div>
          <TitleSection
            title={t("spinAndSpoilMom2025.content.title")}
            text={t("spinAndSpoilMom2025.content.text")}
            borderColor="#c23c4f"
            dark={true}
          />
          <Row className="g-4 mb-5 mt-0 align-items-center" id="">
            <Col className="mt-0" xs={12} md={12}>
              <ReactMarkdown>
                {t("spinAndSpoilMom2025.content.howItworks")}
              </ReactMarkdown>
              <ReactMarkdown className="mt-5">
                {t("spinAndSpoilMom2025.content.prizes")}
              </ReactMarkdown>
            </Col>
          </Row>
        </container-div>
      </MainContentDiv>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: auto;
  .title-section {
    p {
      padding: 0;
      font-size: 20px;
    }
  }
  .hero_text_container {
    /* display: none; */
    /* background-color: rgba(0, 0, 0, 0.1); */
    h1 {
      max-width: 900px;
    }
  }
  .hero_image_container {
    padding: 20px;
  }
  .subtitle-section {
    h2 {
      font-size: 1.8rem;
    }
  }
  strong {
    color: #c23c4f;
  }
`;

const MainContentDiv = styled.div`
  /* background: ${(props) => `url(${props.bgImage})`}; */
  background-color: rgb(232, 235, 239);
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  container-div {
    background: var(--white);
  }
  @media (min-width: 992px) {
    padding: 3rem 0;
  }
  height: auto;
  width: 100%;
  text-align: left;
  ul {
    list-style: none;
    p {
      margin: 10px 0;
    }
  }
  .row {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    &:nth-child(3),
    &:nth-child(5) {
      flex-direction: row-reverse;
    }
    .col-12 {
      .subtitle-section {
        @media (min-width: 768px) {
          padding-top: 0;
        }
      }
    }
  }
`;

export default SpinAndSpoilMom2025;
