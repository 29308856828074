import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Markdown from "src/components/Markdown";
import { useTranslation } from "react-i18next";

const TitleSection = ({ title, text, as: Tag = 'h1', dark=false, className, borderColor }) => {
  const { t } = useTranslation();
  return (
    <Section dark={dark} className={`title-section ${className || ''}`} borderColor={borderColor}>
      <Tag>{t(title)}</Tag>
      <span>{""}</span>
      <Markdown>{text}</Markdown>
    </Section>
  );
};

TitleSection.propTypes = {
  title: PropTypes.string.isRequired,
  text: PropTypes.string,
  dark: PropTypes.bool,
  borderColor: PropTypes.string
};

const Section = styled.div`
  padding: 40px 0;
  height: fit-content;
  width: 100%;
  color: var(--white);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  text-align: center;
  color: ${(props) => (props.dark ? "var(--black)" : "var(--white)")};

  & > h1, & > h2 {
    margin: 0;
    font-weight: 900;
    margin-bottom: 10px;
    font-size: 45px;
  }
  & > h3 {
    margin: 0;
    font-weight: 900;
    margin-bottom: 10px;
    font-size: 35px;
  }
  & > span {
    width: 100%;
    max-width: 275px;
    border-bottom: 2px solid ${(props) => (props.borderColor ? props.borderColor : "var(--red)")};
    margin-bottom: 20px;
  }
  & > p:first-of-type {
    width: 100%;
    max-width: 800px;
    text-align: center;
    /* font-weight: 200; */
    font-size: 1.2rem;
    padding: 0;
  }
  @media screen and (max-width: 991px) {
    padding: 20px 0;
    & > h1, & > h2 {
      text-align: center;
      font-size: 32px;
      padding: 0 20px;
    }
    & > p:first-of-type {
      padding: 0 20px;
    }
  }
`;

export default TitleSection;
