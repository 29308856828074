import React from "react";
import styled from "styled-components";
import { useAccount } from "../context/account/account.provider";
import { useTranslation } from "react-i18next";
import Hero from "../components/Hero/Hero";
import useResponsiveImageUrl from "../utils/useResponsiveImageUrl";
import { Col, Row, Table } from "react-bootstrap";
import StatusCard from "src/components/Account/StatusCard";
import PointsCard from "src/components/Account/PointsCard";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import { darkCardStyle } from "src/GlobalStyle";


const MyAccount = () => {
  const { t } = useTranslation();
  const { accountInfo } = useAccount();
  const balance = accountInfo?.data.balance_egd;
  const currentTier  = accountInfo?.data.current_tier;
  const earnedTransactions = accountInfo?.data.earned_transactions;
  const redeemedTransactions = accountInfo?.data.redeemed_transactions;

      // balance = 16250;
      // currentTier = 2;
      // balance= 156_500;
      // currentTier= 3;
      // balance= 356_500;
      // currentTier= 4;

  const responsiveBgImage = useResponsiveImageUrl("about/about-background.jpg");

  return (
    <MyAccountDiv bgImage={responsiveBgImage}>
      <Hero
        mainHeroText={t("myAccount.myPG")}
        bgImage={"about/about-us-hero-bg_v2.jpg"}
        darkenBg={true}
      >
        <h2>
          {t("myAccount.welcome")}{" "}
          {accountInfo?.data.name && accountInfo.data.name}!
        </h2>
        <p>{t("myAccount.heroDesc")}</p>
      </Hero>

      <container-wide>
        <Row xs={1} lg={2}>

          <Col>
            <MyInfo className="mt-3">
              <SubTitleSection
                subTitle={t("myAccount.myInfo")}
                className="pt-0"
              />
              <Table responsive variant="dark">
                <tbody>
                  <tr>
                    <td>{t("myAccount.name")}</td>
                    <td>{accountInfo?.data.name}</td>
                  </tr>
                  <tr>
                    <td>{t("myAccount.playerNumber")}</td>
                    <td>{accountInfo?.data.player_number}</td>
                  </tr>
                  <tr>
                    <td>{t("myAccount.email")}</td>
                    <td>{accountInfo?.data.email}</td>
                  </tr>
                </tbody>
              </Table>
            </MyInfo>
            <StatusCard
              accountInfo={accountInfo}
            />
          </Col>
          <Col>
            <PointsCard
              earnedTransactions={earnedTransactions}
              redeemedTransactions={redeemedTransactions}
            />
          </Col>
        </Row>
      </container-wide>
    </MyAccountDiv>
  );
};

const MyAccountDiv = styled.div`
  width: 100%;
  background-color: #2d3436;
  background: #000 url(${(props) => props.bgImage}) 50% 50% fixed no-repeat;
  background-size: cover;
  .hero_container {
    background-position: top;
    h1 {
      margin: 0;
      padding: 3rem 1rem;
      font-size: 2rem;
    }
    .row {
      width: 100%;
    }
    table {
      td {
        color: var(--mutedwhite);
        text-align: left;
        background-color: #00000080;
      }
    }
  }
`;

const MyInfo = styled.div`
  ${darkCardStyle}
  padding: 2rem;
  color: var(--mutedwhite);
  .subtitle-section {
    padding-bottom: 1rem;
    p {
      margin-bottom: 0;
    }
  }
`;

export default MyAccount;
