import { useEffect } from "react";

const FacebookPixel = ({ pixelId, locale, events = ["PageView"] }) => {

  useEffect(() => {
    if (!pixelId) return;

    // Cleanup previous fbq instance if it exists
    if (window.fbq) {
      window.fbq('consent', 'revoke');
      delete window.fbq;
    }

    // Ensure fbq is loaded once
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = true;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = true;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, "script", "https://connect.facebook.net/" + locale + "/fbevents.js");

    // Initialize Facebook Pixel
    window.fbq("init", pixelId);

    // Track events dynamically
    events.forEach((event) => window.fbq("track", event));

    // Cleanup function to remove fbq instance
    return () => {
      if (window.fbq) {
        window.fbq('consent', 'revoke');
        delete window.fbq;
      }
    };
  }, [pixelId]);

  return null;
};

export default FacebookPixel;