import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";

const StyledSection = styled.section`
  margin: 30px 0 20px 0;

  .text-wrapper {
    text-align: center;

    p {
      font-size: 1.2rem;
      margin-bottom: 1rem;

      strong {
        font-weight: bold;
      }
    }
  }

  .image-wrapper {
    img {
      width: 100%;
      height: ${({ textContent }) => (textContent ? "315px" : "500px")};
      border: none;
    }
  }
`;

const TournamentImageSection = ({ imageUrl, imageAlt, textContent }) => {
  return (
    <StyledSection>
      <Row className="align-items-center">
        <Col md={6} className="text-wrapper">
          <ReactMarkdown>{textContent}</ReactMarkdown>
        </Col>
        <Col md={6} className="image-wrapper">
          <ResponsiveImage src={imageUrl} alt={imageAlt} />
        </Col>
      </Row>
    </StyledSection>
  );
};

export default TournamentImageSection;
