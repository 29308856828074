import React from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

import { useTranslation } from "react-i18next";
import TitleSection from "src/components/TitleSection/TitleSection";
import { Row, Col } from "react-bootstrap";
import Hero from "src/components/Hero/Hero";
import EventFinished from "src/components/EventFinished";

const IrishOpen2025 = () => {
  const { t } = useTranslation();
  const now = Date.now();
  // const now = new Date(Date.UTC(2025, 0, 30, 5, 0, 1)).getTime();
  // const now = new Date(Date.UTC(2025, 0, 31, 5, 0, 1)).getTime();
  // const now = new Date(Date.UTC(2025, 2, 3, 5, 0, 5)).getTime();
  const jan30 = new Date(Date.UTC(2025, 0, 30, 5, 0, 0)).getTime();
  const jan31 = new Date(Date.UTC(2025, 0, 31, 5, 0, 0)).getTime();
  const feb3 = new Date(Date.UTC(2025, 1, 3, 5, 0, 0)).getTime();

  return (
    <MainDiv>
      <EventFinished scheduleKeyName="irishOpen2025" eventType="promotion" />
      <Hero
        arrowBounce="true"
        bgImage="events/irish-open-2025/Irish-Open_Carousels-Bg.jpg"
        image="events/irish-open-2025/Irish-Open_Hero-Carousel_Logo.png"
        imageAlt={t("poker.irishOpen2025.headerImgAlt")}
      />
      <container-div>
        <TitleSection
          title={t("poker.irishOpen2025.title")}
          text={t("poker.irishOpen2025.qualifyDescription")}
          dark
          borderColor="#116c01"
        />

        <ReactMarkdown>{t("poker.irishOpen2025.description1")}</ReactMarkdown>
        <ReactMarkdown>{t("poker.irishOpen2025.description2")}</ReactMarkdown>

        <TitleSection
          title={t("poker.irishOpen2025.playgroundQualifyHeader")}
          as="h2"
          dark
          borderColor="#116c01"
          className="pb-0"
        />

        <Row xs={1} md={2} className="g-2">
          <Col>
            <StepContainer>
              <TitleSection
                title={t("poker.irishOpen2025.step1.title")}
                text={t("poker.irishOpen2025.step1.price")}
                as="h4"
                borderColor="#fff"
                className="py-0"
              />

              {/* Remove on Friday Jan 1st 2025 at 5am */}
              { now < jan31 && (
                <>
                  <hr/>
                  <ReactMarkdown>{t("poker.irishOpen2025.step1.details")}</ReactMarkdown>
                  <ReactMarkdown>{t("poker.irishOpen2025.step1.target")}</ReactMarkdown>
                </>
              )}

              {/* Show after Thursday Jan 30th 2025 at 5am */}
              { now >= jan30 && (
                <>
                  <hr/>
                  <ReactMarkdown>{t("poker.irishOpen2025.step1.feb.details")}</ReactMarkdown>
                  <ReactMarkdown>{t("poker.irishOpen2025.step1.feb.target")}</ReactMarkdown>
                </>
              )}

              <hr/>
              <p>{t("poker.irishOpen2025.step1.advance")}</p>
            </StepContainer>
          </Col>
          <Col>
            <StepContainer>
              <TitleSection
                title={t("poker.irishOpen2025.step2.title")}
                text={t("poker.irishOpen2025.step2.price")}
                as="h4"
                borderColor="#fff"
                className="py-0"
                />

              {/* Remove on Monday Feb 3rd 2025 at 5am */}
              { now <= feb3 && (
                <>
                  <hr/>
                  <ReactMarkdown>
                    {t("poker.irishOpen2025.step2.details")}
                  </ReactMarkdown>
                </>
              )}

              {/* Show after Thursday Jan 30th 2025 at 5am */}
              { now >= jan30 && (
                <>
                  <hr/>
                  <ReactMarkdown>
                    {t("poker.irishOpen2025.step2.feb.details")}
                  </ReactMarkdown>
                </>
              )}

              <hr/>
              <p>{t("poker.irishOpen2025.step2.win")}</p>
              </StepContainer>
          </Col>
        </Row>
        <ReactMarkdown className="my-4">{t("poker.irishOpen2025.packageDetails")}</ReactMarkdown>
      </container-div>
    </MainDiv>
  );
};


const StepContainer = styled(Col)`
  background-color: #006601;
  border-radius: 15px;
  padding: 15px;
  color: var(--white);
  text-align: center;
  h4, h5 {
    font-weight: 700;
  }
  .title-section {
    span {
      margin-bottom: 10px;
    }
    p {
      font-weight: 700;
      margin-bottom: 0;
    }
  }
  hr {
    width: 80%;
    margin: 1rem auto;
  }
`;

const MainDiv = styled.div`
  background: var(--white);
  text-align: center;
  h1, h1 {
    color: #032d05;
  }
  .hero_image_container img {
    padding: 0 6rem;
    margin: -2rem 0;
  }
  .hero_text_container {
    display: none;
  }
`;



export default IrishOpen2025;
