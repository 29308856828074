import { useLayoutEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// import i18n from "i18next";
import routesEn from "../locales/en/routes.json";
import routesFr from "../locales/fr/routes.json";
import tournamentsEn from "../locales/en/tournaments.json";
import tournamentsFr from "../locales/fr/tournaments.json";
import highHandsEn from "../locales/en/highHands.json";
import highHandsFr from "../locales/fr/highHands.json";
import { useTranslation } from "react-i18next";

export const useLanguageChanger = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // Function to change language and update the URL accordingly
  const changeLanguage = async (nextLanguage) => {
    const routesConfig = {
      en: routesEn.routes,
      fr: routesFr.routes,
    };

    const tournamentsConfig = {
      en: tournamentsEn.tournaments,
      fr: tournamentsFr.tournaments,
    };

    const highHandConfig = {
      en: highHandsEn.highHands,
      fr: highHandsFr.highHands,
    };

    const currentPathname = window.location.pathname;

    // Extract the current language from the pathname
    const matchLang = currentPathname.match(/\/(fr|en)/);
    const currentLanguage = matchLang ? matchLang[1] : i18n.language;

    // Try to find a static route in routesConfig
    const staticRouteKey = Object.keys(routesConfig[currentLanguage]).find(
      (key) => routesConfig[currentLanguage][key] === currentPathname
    );

    if (staticRouteKey && routesConfig[nextLanguage][staticRouteKey]) {
      // Navigate to the translated route for static paths
      navigate(routesConfig[nextLanguage][staticRouteKey], { replace: true });
    } else {
      // If no static route found, check tournamentsConfig
      const tournamentKey = Object.keys(
        tournamentsConfig[currentLanguage]
      ).find((key) =>
        Object.values(tournamentsConfig[currentLanguage][key]).includes(
          currentPathname
        )
      );

      // If no static route found, check highHandConfig
      const highHandKey = Object.keys(highHandConfig[currentLanguage]).find(
        (key) =>
          highHandConfig[currentLanguage][key] === currentPathname
        );

      if (tournamentKey) {
        const tournamentRouteKey = Object.keys(
          tournamentsConfig[currentLanguage][tournamentKey]
        ).find(
          (key) =>
            tournamentsConfig[currentLanguage][tournamentKey][key] ===
            currentPathname
        );

        if (
          tournamentRouteKey &&
          tournamentsConfig[nextLanguage][tournamentKey]
        ) {
          const translatedRoute =
            tournamentsConfig[nextLanguage][tournamentKey][tournamentRouteKey];
          navigate(translatedRoute, { replace: true });
        } else {
          console.error("No matching tournament route found for translation.");
          return;
        }
      } else if (highHandKey) {
        const highHandRouteKey = Object.keys(
          highHandConfig[currentLanguage][highHandKey]
        ).find(
          () => highHandConfig[currentLanguage][highHandKey] === currentPathname
        );

        if (highHandRouteKey && highHandConfig[nextLanguage][highHandKey]) {
          const translatedRoute = highHandConfig[nextLanguage][highHandKey];
          navigate(translatedRoute, { replace: true });
        } else {
          console.error("No matching high hand route found for translation.");
          return;
        }
      } else {
        // If no tournament route found, check for dynamic routes
        const dynamicRouteKey = Object.keys(routesConfig[currentLanguage]).find(
          (key) => {
            return new RegExp(
              `^${routesConfig[currentLanguage][key].replace(/:[^\s/]+/g, "[^\\/]+")}$`
            ).test(currentPathname);
          }
        );

        if (dynamicRouteKey && routesConfig[nextLanguage][dynamicRouteKey]) {
          const translatedRoutePattern =
            routesConfig[nextLanguage][dynamicRouteKey];
          const dynamicParts = currentPathname.match(
            new RegExp(
              routesConfig[currentLanguage][dynamicRouteKey].replace(
                /:[^\s/]+/g,
                "([^\\/]+)"
              )
            )
          );

          dynamicParts.shift(); // Remove the first element (full string match)

          let translatedRoute = translatedRoutePattern;
          dynamicParts.forEach((part, index) => {
            translatedRoute = translatedRoute.replace(
              new RegExp(`:${Object.keys(dynamicParts)[index]}`),
              part
            );
          });

          navigate(translatedRoute, { replace: true });
        } else {
          console.error(
            "No matching static or dynamic route found for translation."
          );
          return;
        }
      }
    }

    // Change language with i18next
    i18n.changeLanguage(nextLanguage);

    // Update the language setting in local storage
    localStorage.setItem("language", nextLanguage);
  };

  // Automatically detect changes in the URL and force a language change if needed
  useLayoutEffect(() => {
    const currentPathname = window.location.pathname;
    const matchLang = currentPathname.match(/\/(fr|)/);
    const currentLanguageFromUrl =
      matchLang && matchLang[1] === "fr" ? "fr" : "en";

    if (i18n.language !== currentLanguageFromUrl) {
      i18n.changeLanguage(currentLanguageFromUrl).then(() => {
        // window.location.reload();
      });
    }
  }, [i18n.language, location.pathname]);

  return changeLanguage;
};
