// This is your password validation logic extracted into a utility function.
export const validatePassword = (password) => {
  const validations = [
    {
      regex: /[A-Z]/,
      message: "uppercase",
    },
    {
      regex: /[a-z]/,
      message: "lowercase",
    },
    {
      regex: /\d/,
      message: "number",
    },
    {
      test: (p) => p.length >= 8,
      message: "length",
    },
  ];

  const failedValidations = validations.flatMap((v) =>
    typeof v.regex !== "undefined"
      ? !v.regex.test(password)
        ? [v.message]
        : []
      : !v.test(password)
        ? [v.message]
        : []
  );

  return failedValidations.length === 0 ? true : failedValidations;
};
