import React from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";

const Markdown = ({ children, className }) => (
  <ReactMarkdown
    className={className}
    components={{
      a: ({ href, children }) => <Link to={href}>{children}</Link>,
    }}
  >
    {children}
  </ReactMarkdown>
);

export default Markdown;
