import { t } from "i18next";
import Button from "../../Button/Button";
import styled from "styled-components/macro";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";

const CoverageAndResultsBanner = ({
  resultsLink,
  label,
  hotels,
  pokerNews,
  pokerOrgLogo,
  buttonOptions = {},
}) => {
  return (
    <Banner hotels={hotels} pokerNews={pokerNews} variant={hotels}>
      <h2>
        {label ? label : t("poker.tournaments.coverAndEventsBanner.text")}
      </h2>
      {pokerNews && (
        <ResponsiveImage src={pokerOrgLogo ? "svg/Poker.org-logo.svg" : "svg/pokernews.svg"} alt="pokernews-logo" />
      )}
      {resultsLink && (
        <Button
          href={resultsLink}
          onClick={buttonOptions.onClick}
          label={t("poker.tournaments.coverAndEventsBanner.btnText")}
          hoverBgColor={hotels ? "white" : "black"}
          hoverColor={hotels ? "black" : "white"}
        />
      )}
    </Banner>
  );
};

const Banner = styled.div`
  background-color: ${({ pokerNews, variant }) =>
    pokerNews ? "var(--grey)" : variant === "light" ? "#f5f5f5" : "#131619"};
  color: ${({ variant }) => (variant === "light" ? "#000" : "var(--white)")};
  margin: ${({ hotels }) => (hotels ? "0" : "2rem auto")};
  border-radius: ${({ hotels }) => (hotels ? "0" : "4px")};
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  h2 {
    font-size: 1.5rem;
  }
  a {
    margin: 1.2rem;
  }
  img {
    object-fit: contain;
    width: 100%;
    max-width: 150px;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 1rem;
    h2 {
      margin: 0;
      text-align: center;
    }
    a {
      margin-top: 20px;
    }
  }
`;

export default CoverageAndResultsBanner;
