import styled from "styled-components";
import Hero from "src/components/Hero/Hero";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import SubTitleSection from "src/components/SubTitleSection/SubTitleSection";
import EventFinished from "src/components/EventFinished";
import useResponsiveImageUrl from "src/utils/useResponsiveImageUrl";

const EasterEggStravaganza2025 = () => {
  const { t, i18n } = useTranslation();

  return (
    <Div>
      <Hero
        bgImage="promotions/gaming-machines/easter-egg-stravaganza-2025/PG-Easter-Egg-stravaganza-hero-image-2400x800.jpg"
        buttonSettings={null}
        image={`promotions/gaming-machines/easter-egg-stravaganza-2025/Easter-Egg-Stravaganza-logo-2400px-${i18n.language}.png`}
        title={t("easterEggStravaganza2025.title")}
      >
      </Hero>
      <EventFinished
        scheduleKeyName="easterEggStravaganza2025"
        eventType="promotion"
      />
      <MainContentDiv
        bgImage={useResponsiveImageUrl(
          "promotions/gaming-machines/saint-patricks-day-2025/St-Patricks-Day-secondary-LP-image-2400x1600-en.jpg"
        )}
      >
        <container-div>
          <Row className="g-4 mb-5 align-items-center" id="">
            <Col xs={12} md={6}>
              <ResponsiveImage
                src={"promotions/gaming-machines/easter-egg-stravaganza-2025/Easter-Egg-Stravaganza-LP-Card-1600x1067.jpg"}
                alt={t("easterEggStravaganza2025.title")}
              />
            </Col>
            <Col xs={12} md={6}>
              <SubTitleSection
                subTitle={t("easterEggStravaganza2025.content.title")}
                text={t("easterEggStravaganza2025.content.text")}
                borderColor="#E900B4"
              />
            </Col>
          </Row>
        </container-div>
      </MainContentDiv>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  height: auto;
  .title-section {
    p {
      padding: 0;
      font-size: 20px;
    }
  }
  .hero_text_container {
    /* display: none; */
    h1 {
      max-width: 900px;
    }
  }
  .hero_image_container {
    padding: 20px;
  }
  .subtitle-section {
    h2 {
      font-size: 1.8rem;
    }
  }
  ul {
    list-style: none;
  }
`;

const MainContentDiv = styled.div`
  /* background: ${(props) => `url(${props.bgImage})`}; */
  background-color: var(--lightblack);
  background-position: top;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  container-div {
    background: rgba(0, 0, 0, 0.5);
  }
  @media (min-width: 992px) {
    padding: 3rem 0;
  }
  height: auto;
  width: 100%;
  color: var(--white);
  text-align: left;
  .row {
    display: flex;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    position: relative;
    &:nth-child(3), &:nth-child(5) {
      flex-direction: row-reverse;
    }
    .col-12 {
      .subtitle-section {
        @media (min-width: 768px) {
          padding-top: 0;
        }
      }
    }
  }
`;

export default EasterEggStravaganza2025;
