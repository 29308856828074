import highHandsEn from "src/locales/en/highHands.json";
import highHandsFr from "src/locales/fr/highHands.json";

export const getHighHandData = async (location, i18n) => {
  let highHandData = null;

  try {
    const { pathname } = location;
    const highHandsConfig =
      i18n.language === "fr" ? highHandsFr.highHands : highHandsEn.highHands;

    for (const highHandKey in highHandsConfig) {
      const pages = highHandsConfig[highHandKey];
      if ( pathname.includes(pages)) {
        // You have found the matching keys
        // console.log(`Matched High Hand Key: ${highHandKey}`);

        const highHandDataLocale = await import(
          /* webpackChunkName: "high-hand-data" */ `src/locales/${i18n.language}/highHands/${highHandKey}.json`
        );
        highHandData = highHandDataLocale[highHandKey] || null;
        return { highHandData, highHandKey }; // Return early upon finding a match
      }
    }

    throw new Error(
      `No matching high hand or tab found for pathname: ${pathname}`
    );
  } catch (error) {
    console.error(
      `Error loading high hand data for pathname ${location.pathname}: ${error.message}`
    );
    throw new Error(
      `No matching high hand or tab found for pathname: ${pathname}`
    );
  }
};
