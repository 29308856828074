import styled from "styled-components";
import Hero from "src/components/Hero/Hero";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ReactMarkdown from "react-markdown";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import TitleSection from "src/components/TitleSection/TitleSection";
import Button from "src/components/Button/Button";

const RoomsForRent = () => {
  const { t } = useTranslation();

  return (
    <RoomsForRentDiv>
      <Hero
        bgImage="private-events/Group-events-header-2400x800.jpg"
        buttonSettings={null}
        description={t("roomsForRent.hero.title")}
        descriptionBgColor="#0000004d"
      >
      </Hero>
      <MainContentDiv>
        <div className="white-bg">
          <container-div>
            <TitleSection
              as={"h2"}
              title={t("roomsForRent.content.title")}
              text={t("roomsForRent.content.text")}
              dark={true}
            />
          </container-div>
        </div>
        <ResponsiveImage
          src="private-events/group-events-collage-2400x800.jpg"
          alt={t("roomsForRent.hero.title")}
        />
        <container-div>
          <TitleSection
            as={"h2"}
            title={t("roomsForRent.content.whyChoosePg.title")}
          />
          <center-content>
            <Row className="g-6 mb-5 list-row" xs={1} md={2}>
              <Col className="m-0 p-0">
                {t("roomsForRent.content.whyChoosePg.list", {
                  returnObjects: true,
                })?.map((item, index) => (
                  <div key={index} className="flex-row">
                    <ResponsiveImage
                      className="icons"
                      src={"svg/Check.svg"}
                      alt="check-icon"
                    />
                    <ReactMarkdown>{item}</ReactMarkdown>
                  </div>
                ))}
              </Col>
              <Col className="m-0 p-0">
                {t("roomsForRent.content.whyChoosePg.listTwo", {
                  returnObjects: true,
                })?.map((item, index) => (
                  <div key={index} className="flex-row">
                    <ResponsiveImage
                      className="icons"
                      src={"svg/Check.svg"}
                      alt="check-icon"
                    />
                    <ReactMarkdown>{item}</ReactMarkdown>
                  </div>
                ))}
              </Col>
            </Row>
          </center-content>
        </container-div>
        <div className="white-bg">
          <container-div>
            <TitleSection
              as={"h2"}
              title={t("roomsForRent.content.title")}
              text={t("roomsForRent.content.text")}
              dark={true}
            />
            <Row className="mb-3 text-center">
              <Col md={6}>
                <div>
                  <ResponsiveImage
                    src={"private-events/etc-2400x1600.jpg"}
                    alt="check-icon"
                  />
                  <ReactMarkdown>
                    {t("roomsForRent.content.restos.etc.text")}
                  </ReactMarkdown>
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <ResponsiveImage
                    src={"private-events/DD-2400x1600.jpg"}
                    alt="dd"
                  />
                  <ReactMarkdown>
                    {t("roomsForRent.content.restos.drunkenDragon.text")}
                  </ReactMarkdown>
                </div>
              </Col>
            </Row>

            <Row className="mb-3 text-center">
              <Col md={6}>
                <div>
                  <ResponsiveImage
                    src={
                      "private-events/babel-2400x1600.jpg"
                    }
                    alt="check-icon"
                  />
                  <ReactMarkdown>
                    {t("roomsForRent.content.restos.babel.text")}
                  </ReactMarkdown>
                </div>
              </Col>
              <Col md={6}>
                <div>
                  <ResponsiveImage
                    src={
                      "private-events/esperanto-2400x1600.jpg"
                    }
                    alt="check-icon"
                  />
                  <ReactMarkdown>
                    {t("roomsForRent.content.restos.esperanto.text")}
                  </ReactMarkdown>
                </div>
              </Col>
            </Row>
            <Row className="mb-5 text-center">
              <Col>
                <div>
                  <ResponsiveImage
                    src={"private-events/PG-2400x780.jpg"}
                    alt="etc"
                  />
                  <ReactMarkdown>
                    {t("roomsForRent.content.restos.barAndStageArea.text")}
                  </ReactMarkdown>
                </div>
              </Col>
            </Row>
          </container-div>
        </div>
      </MainContentDiv>
      <container-div>
        <center-content>
          <TitleSection
            className={"title-section-book"}
            as={"h2"}
            title={t("roomsForRent.content.bookYourEvent.title")}
            text={t("roomsForRent.content.bookYourEvent.text")}
          />
        </center-content>
        <Row className="m-auto text-center mb-4">
          <Button
            bgColor="var(--red)"
            color="var(--white)"
            hoverColor="var(--black)"
            hoverBgColor="var(--white)"
            width="wide"
            href={t("routes.roomsForRentForm")}
            label={t("roomsForRent.content.bookYourEvent.link")}
          />
          <ReactMarkdown>
            {t("roomsForRent.content.bookYourEvent.smallText")}
          </ReactMarkdown>
        </Row>
      </container-div>
      <div className="white-bg">
        <container-div>
          <TitleSection
            as={"h2"}
            title={t("roomsForRent.content.coachBus.title")}
            dark={true}
            className={"grid pb-2"}
          />
          <Row className="mb-3 contact-us">
            <Col md={6}>
              <ReactMarkdown>
                {t("roomsForRent.content.coachBus.text")}
              </ReactMarkdown>
              <Button
                bgColor="var(--red)"
                color="var(--white)"
                hoverColor="var(--white)"
                hoverBgColor="var(--black)"
                width="wide"
                href={t("routes.roomsForRentForm")}
                label={t("roomsForRent.content.bookYourEvent.link")}
              />
            </Col>
            <Col md={6}>
              <ResponsiveImage
                src={"private-events/Bus-2400x1600.jpg"}
                alt="bus"
              />
            </Col>
          </Row>
          <TitleSection
            as={"h2"}
            title={t("roomsForRent.content.pokerHome.title")}
            dark={true}
            className={"grid pb-2"}
          />

          <Row className="mb-3 pb-5 contact-us">
            <Col md={6} className="d-flex flex-column justify-content-center">
              <ReactMarkdown>
                {t("roomsForRent.content.pokerHome.text")}
              </ReactMarkdown>
              <Button
                bgColor="var(--red)"
                color="var(--white)"
                hoverColor="var(--white)"
                hoverBgColor="var(--black)"
                width="wide"
                href={t("routes.roomsForRentForm")}
                label={t("roomsForRent.content.bookYourEvent.link")}
              />
            </Col>
            <Col md={6}>
              <ResponsiveImage
                src={"private-events/Poker-2400x1600.jpg"}
                alt="poker"
              />
            </Col>
          </Row>
        </container-div>
      </div>
    </RoomsForRentDiv>
  );
};

const RoomsForRentDiv = styled.div`
  width: 100%;
  height: auto;
  background: var(--lightblack);
  color: var(--white);
  padding-bottom: 1rem;
  container-div {
    background-color: unset !important;
  }
  .hero_container {
    min-height: 500px;
  }
  .small {
    text-align: center;
  }
  .title-section {
    &.grid {
      margin-bottom: 0;
    }
    h4,
    p {
      margin: 0;
      padding: 0;
    }
  }
  .white-bg {
    background: var(--white);
    color: var(--black);
  }
  .icons {
    width: 24px;
    margin-right: 1rem;
  }
  .col {
    display: flex;
    flex-direction: column;
  }
  .flex-row {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    p {
      margin: 0;
    }
  }
  .list-row {
    display: flex;
    justify-content: center;
    max-width: 960px;
    margin: 0 auto;
  }
  .title-section-book {
    padding-bottom: 0;
  }
  .contact-us {
    max-width: 1100px;
    margin: 1rem auto;
    img {
      max-height: 250px;
      object-fit: cover;
    }
  }
  .row {
    &:nth-child(4) {
      flex-direction: row-reverse;
    }
  }
  @media (max-width: 768px) {
    .col {
      margin: 0;
      display: flex;
      justify-content: center;
    }
    .title-section {
      margin: 2rem 0;
      padding: 0;
    }
  }
`;

const MainContentDiv = styled.div`
  h2:after {
    border-color: #f44c00;
  }
  container-div {
    background: rgba(0, 0, 0, 0.5);
  }
  /* @media (min-width: 992px) {
    padding: 3rem 0;
  } */
  height: auto;
  width: 100%;
  text-align: left;
  .p_1 strong {
    white-space: nowrap;
  }
  .desc strong {
    color: #f44c00;
  }
  p {
    margin-bottom: 2rem;
  }
`;

export default RoomsForRent;
