import React, { useState } from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";

const TournamentDropDownText = ({ dropdownText }) => {
  const [viewBtn, setViewBtn] = useState(false);
  const [viewBtnTwo, setViewBtnTwo] = useState(false);
  return (
    <Wrapper className="tournament-drop-down-text">
      <button
        onClick={() => {
          dropdownText?.list && setViewBtn(!viewBtn);
          dropdownText?.listTwo && setViewBtnTwo(!viewBtnTwo);
        }}
      >
        {dropdownText.label}
        <span className={viewBtn ? "open" : ""}></span>
      </button>

      <div className={viewBtn ? "ol-active" : undefined}>
        <ReactMarkdown>{dropdownText?.title}</ReactMarkdown>

        {/* Check if list is a string or an object */}
        {typeof dropdownText?.list === "string" ? (
          <ReactMarkdown>{dropdownText.list}</ReactMarkdown> // Render markdown list
        ) : (
          <ol>
            {dropdownText?.list &&
              Object.values(dropdownText.list).map((term, index) => (
                <li key={index}>
                  <ReactMarkdown>{term}</ReactMarkdown>
                </li>
              ))}
          </ol>
        )}

        <ReactMarkdown>{dropdownText?.text}</ReactMarkdown>
      </div>

      {dropdownText?.textTwo && (
        <div className={viewBtnTwo ? "ol-active" : undefined}>
          <ReactMarkdown>{dropdownText.titleTwo}</ReactMarkdown>

          {typeof dropdownText?.listTwo === "string" ? (
            <ReactMarkdown>{dropdownText.listTwo}</ReactMarkdown> // Render markdown list
          ) : (
            <ol>
              {dropdownText?.listTwo &&
                Object.values(dropdownText.listTwo).map((term, index) => (
                  <li key={index}>
                    <ReactMarkdown>{term}</ReactMarkdown>
                  </li>
                ))}
            </ol>
          )}

          <ReactMarkdown>{dropdownText.textTwo}</ReactMarkdown>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: auto;
  margin-bottom: 20px;
  text-align: left;
  button {
    margin: 20px 0;
    width: 100%;
    max-width: 300px;
    border: none;
    height: 40px;
    background-color: #ddd;
    outline: 0;
    color: var(--black);
    border-radius: 0;
    padding: 0;
    cursor: pointer;
    & > span {
      display: inline-block;
      width: 0;
      height: 0;
      margin-left: 10px;
      vertical-align: middle;
      border-top: 4px solid;
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      transition: transform 0.3s ease-in-out;
    }

    & > span.open {
      transform: rotate(180deg);
    }
  }

  div {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;

    ol {
      padding: 20px;
      margin: 0;

      li {
        padding: 3px;
        font-size: 14px;
        color: #555;
      }
    }

    &.ol-active {
      max-height: fit-content;
    }
  }
`;

export default TournamentDropDownText;
