// components/Navbar/NavQuickLinks.jsx
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useHomepage } from "src/context/homepage/homepage.provider";
import { useLanguageChanger } from "../../middleware/useLanguageChanger";
import { useAccount } from "src/context/account/account.provider";
import { Link } from "react-router-dom";
import { Dropdown, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faSignOut, faCaretDown, faSignIn, faGlobe, faUserCircle, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components/macro";
import { useTranslation } from "react-i18next";

const NavQuickLinks = ({
  closeAllMenus,
  scrolling
}) => {
  const changeLanguage = useLanguageChanger();
  const { access_token, logout } = useAccount();
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation();
  const { darkTheme } = useHomepage();

  useEffect(() => {
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);

  return (
    <NavQuickLinksDiv
      className="nav-quick-links"
      scrolling={scrolling}
    >
      {access_token ? (
        <Dropdown className="nav-item">
          <Dropdown.Toggle as={Nav.Link} className="nav-link account-dropdown" id="dropdown-basic">
            <FontAwesomeIcon icon={faUserCircle} aria-hidden="true" />
            <span className="title">
              {t("nav.account")}
              <FontAwesomeIcon icon={faCaretDown} className="caret-icon" />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu align="end">

            <Dropdown.Item as={Link} to={t("routes.myAccount")} onClick={closeAllMenus}>
              <FontAwesomeIcon icon={faUserCircle} aria-hidden="true" /> {t("nav.myPG")}
            </Dropdown.Item>

            <Dropdown.Item as={Link}
              to={t("routes.accountSettings")}
              onClick={closeAllMenus}
            >
              <FontAwesomeIcon icon={faCog} aria-hidden="true" /> {t("nav.accountSettings")}
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item className="red" onClick={() => {
              logout();
              closeAllMenus();
              }}
            >
              <FontAwesomeIcon icon={faSignOut} aria-hidden="true" /> {t("button.sign-out")}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <Nav.Link
          as={Link}
          to={t("routes.login")}
          onClick={closeAllMenus}
        >
          <FontAwesomeIcon icon={faSignIn} aria-hidden="true" />
          <span className="title">
            {t("nav.signIn")}
          </span>
        </Nav.Link>
      )}

      <Nav.Link
        as={Link}
        to={t("routes.visitUs")}
        onClick={closeAllMenus}
        className="visit"
      >
        <FontAwesomeIcon icon={faLocationDot} aria-hidden="true" />
        <span className="title">
          {t("nav.visitUs")}
        </span>

      </Nav.Link>
      <button
        type="button"
        className="nav-link language"
        onClick={() => {
          changeLanguage(i18n.language === "en" ? "fr" : "en");
          // closeAllMenus();
        }}
      >
        <FontAwesomeIcon icon={faGlobe} aria-hidden="true" />
        <span className="title">
          {i18n.language === "en" ? "Français" : "English"}
        </span>

      </button>
    </NavQuickLinksDiv>
  );
};

const NavQuickLinksDiv = styled.main.withConfig({
  shouldForwardProp: (prop) =>
      !["scrolling"].includes(prop),
  })`
  position: absolute;
  right: 0%;
  top: 0%;
  display: flex;
  margin-right: 0.5rem;
  z-index: 2000;
  .dropdown-item.active, .dropdown-item:active {
    background-color: var(--white);
  }
  .nav-link {
    padding: 1.05rem 0.6rem 0.8rem 0.6rem;
    display: flex;
    flex-direction: column;
    color: var(--white);
    opacity: 0.8;
    &:active, &:focus {
      opacity: 1;
    }
    svg {
      height: 25px;
      margin: 0 auto;
    }
    .title {
      font-size: 9px;
      margin-top: 9px;
      font-weight: 700;
    }
    &.account-dropdown {
      position: relative;
      &:after {
        display: none;
      }
      .caret-icon {
        width: 12px;
        height: 10px;
        margin-left: 1px;
        @media screen and (min-width: 991.98px){
          width: 14px;
          height: 12px;
          margin-bottom: 4px;
          margin-left: 3px;
        }
      }
    }
    @media (max-width: 575.98px) {
      &.language
      {
        display: none;
        padding-left: 0.3rem;
        padding-right: 0.3rem;
      }
    }
    @media (max-width: 375.98px) {
      &.visit
      {
        display: none;
      }
    }

  }
  @media (min-width: 992px) {
    margin-right: 0;
    top: ${({ scrolling }) => (scrolling ? "-50px" : "0")};
    transition: top 0.3s;
    background-color: rgb(255 255 255 / 90%);
    height: 45px;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    gap: 1.2rem;
    padding: 0 1rem 0 1rem;
    :before {
      content: '';
      position: absolute;
      left: -50px;
      width: 50px;
      height: 100%;
      background-color: rgb(255 255 255 / 90%);
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 74% 100%, 94% 100%);
      z-index: -1;
    }
    .nav-link {
      flex-direction: row;
      padding: 0;
      cursor: pointer;
      &:hover {
        opacity: 1;
        .title {
          color: var(--red);
        }
      }
      .title {
        color: var(--black);
        font-size: 1rem;
        font-weight: normal;
        margin: 0;
      }
      svg {
        height: 20px;
        margin-right: 0.5rem;
        color: var(--black);
        position: relative;
        top: 2px;
      }
    }
  }

`;

export default NavQuickLinks;
