import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import styled from "styled-components/macro";
import Slider from "react-slick";
import Hero from "../Hero/Hero";
import { shouldPublish } from "src/utils/publishUtils";
import moment from "moment";
import { TbRectangleVerticalFilled } from "react-icons/tb";
import { HiArrowLeftEndOnRectangle, HiArrowRightEndOnRectangle } from "react-icons/hi2";

const HeroCarousel = ({ carouselData, slidesToShow, slidesToScroll }) => {
  const [slideIndex, setSlideIndex] = useState(0);
  const { t, i18n } = useTranslation();
  const sliderRef = React.createRef();
  const defaultData = [t('defaultHeroCarousel.loading', { returnObjects: true })];
  const carouselDataValues = carouselData || defaultData;

  const NextArrow = ({ onClick }) => (
    <button
      type="button"
      className="slick-next"
      onClick={onClick}
      style={{
        display: "block",
        background: "none",
        border: "none",
        color: "white",
        fontSize: "24px",
        position: "absolute",
        right: "10px",
        zIndex: 1,
      }}
    >
      <HiArrowRightEndOnRectangle />
    </button>
  );

  const PrevArrow = ({ onClick }) => (
    <button
      type="button"
      className="slick-prev"
      onClick={onClick}
      style={{
        display: "block",
        background: "none",
        border: "none",
        color: "white",
        fontSize: "24px",
        position: "absolute",
        left: "10px",
        zIndex: 1,
      }}
    >
      <HiArrowLeftEndOnRectangle />
    </button>
  );

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    lazyLoad: true,
    speed: 800,
    slidesToShow: slidesToShow || 1,
    slidesToScroll: slidesToScroll || 1,
    beforeChange: (current, next) => setSlideIndex(next),
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    customPaging: (i) => (
      <TbRectangleVerticalFilled />
    ),
    appendDots: (dots) => (
      <div>
        <ul>{dots}</ul>
      </div>
    ),
  };

  const shouldShowSlide = (slide) => {
    const showOnDate = slide.showDate ? moment(slide.showDate, "YYYY-MM-DD") : null;
    const removeDate = slide.removeDate ? moment(slide.removeDate, "YYYY-MM-DD") : null;
    const today = moment();

    return (
      (!showOnDate || today.isSameOrAfter(showOnDate, "day")) && // On or after showOn date
      (!removeDate || today.isBefore(removeDate, "day")) // Before removeDate
    );
  };

  return (
    <CarouselContainer className="hero-carousel">
      <Slider {...settings} ref={sliderRef}>
        {carouselDataValues.map((slide, index) => {
          // Use the shouldPublish utility to check if the slide should be shown
          const shouldShowSlide = shouldPublish({
            publish_at: slide.publish_at,
            unpublish_at: slide.unpublish_at
          });

          if (!shouldShowSlide) return null;

          return (
            <Hero
              key={index}
              image={slide.image}
              imageAlt={slide.image_alt || slide.title}
              bgImage={slide.bg_image}
              title={slide.title}
              titleAs={"span"}
              description={slide.description}
              buttonSettings={{
                label: `${slide.button_text || t("button.check-it-out")}`,
                href: `${slide.button_link}`,
                size: "large",
                width: "wide",
                color: "black",
                bgColor: "white",
                specialHover: slide.button_disabled === "true" ? false : true,
                disabled: slide.button_disabled === "true",
              }}
              descriptionBgColor={slide.description_bg_color || "#0000006d"}
            />
          );
        })}
      </Slider>
      {carouselDataValues?.map((slide, index) => {
        if (!shouldShowSlide(slide)) return null;

        return <h2 key={index} className="carousel-slide-title">{slide.title}</h2>;
      })}
    </CarouselContainer>
  );
};

HeroCarousel.propTypes = {
  carouselData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      bg_image: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      image_alt: PropTypes.string,
      button_link: PropTypes.string.isRequired,
      button_text: PropTypes.string,
      button_disabled: PropTypes.string,
    })
  ).isRequired,
  slidesToShow: PropTypes.number,
  slidesToScroll: PropTypes.number,
};

const CarouselContainer = styled.div.withConfig({
  shouldForwardProp: (prop) => !["slideCount", "currentSlide"].includes(prop),
})`
  width: 100%;
  display: flex;
  flex-direction: column;
  h2.carousel-slide-title {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 0.1rem;
  }
  @media (max-width: 991px) {
    margin-top: 80px;
    padding-top: 0;
  }
  .slick-track, .hero_container {
    height: 88vh !important;
    width: 100%;
  }
  @media (max-width: 991px) {
    .hero_container {
      margin-top: 0;
      padding-top: 0;
    }
    .slick-track, .hero_container {
      height: calc(88vh - 80px) !important;
      width: 100%;
    }
  }

  .slick-prev, .slick-next {
    top: initial;
    z-index: 1;
    width: 30px;
    height: 30px;
    opacity: 0.8;
    transition: opacity 0.3s;
    border-radius: 5px;
    font-size: 34px !important;
    bottom: 14px;
    svg {
      transition: all 0.3s;
    }
    &:hover {
      opacity: 1;
      svg {
        transform: scale(1.3);
      }
    }
    &:before {
      content: "";
      display: none;
    }
  }
  .slick-prev {
    left: 1rem!important;
  }
  .slick-next {
    right: 1rem!important;
  }
  @media (min-width: 768px) {
    .slick-prev, .slick-next  {
      font-size: 40px !important;
      bottom: 59px;
      z-index: 1;
      width: 50px;
      height: 50px;
    }
    .slick-prev {
      left: 2rem!important;
    }
    .slick-next {
      right: 2rem!important;
    }
  }
  .slick-dots {
    bottom: 34px;
    ul {
      margin: 0;
      padding-left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    li {
      opacity: 0.5;
      transition: all 0.3s;
      color: var(--white);
      &.slick-active {
        opacity: 1;
        svg {
          transform: scale(2);
        }
      }
    }
  }
  .hero_image {
    object-fit: contain;
    height: 100%;
  }
  .hero_image_container {
    flex-shrink: 1;
    overflow: hidden;
  }
  .hero_text_container {
    bottom: 0;
    width: 100%;
    flex-grow: 1;
    justify-content: space-evenly;
    p {
      margin: 10px 0;
    }
    .hero_button {
      margin-bottom: 3.9rem;
      &.disabled {
        color: #bdbdbd;
      }
    }
  }
`;

export default HeroCarousel;
