import React, { useEffect, useLayoutEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getHighHandData } from "src/utils/highHandUtils";
import Page404 from "src/pages/Page404";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Hero from "src/components/Hero/Hero";
import ReactMarkdown from "react-markdown";
import TitleSection from "src/components/TitleSection/TitleSection";
import CashGamesInfo from "src/components/Poker/CashGamesInfo";
import EventFinished from "src/components/EventFinished";
import Button from "src/components/Button/Button";
import { Col, Row } from "react-bootstrap";
import { BiSolidDollarCircle } from "react-icons/bi";
import { LuAlarmClock } from "react-icons/lu";
import ImageLink from "src/components/ImageWrapper/ImageLink";
import { useErs } from "src/context/ers/ers.provider";
import { formatAmount } from "src/utils/formatters";
import { getScheduleDates, shouldPublish } from "src/utils/publishUtils";

const tabTranslations = {
  en: {
    highHand: "highHand",
    anniversary: "anniversary",
  },
  fr: {
    highHand: "mainHaute",
    anniversary: "anniversaire",
  },
};

const HighHandMain = () => {
  const location = useLocation();
  const { i18n, t } = useTranslation();
  const { data, fetchData } = useErs();
  const [highHandContent, setHighHandContent] = useState(null);
  const [highHandTabs, setHighHandsTabs] = useState(null);
  const lng = i18n.language;
  const [isNotFound, setIsNotFound] = useState(false);
  const [key, setKey] = useState(null);
  const [tab, setTab] = useState(() => {
    const hash = window.location.hash.replace("#", "");
    return hash || "highHand"; // Default to "highHand" if no hash
  });

  // Fetch high hand data when location, language, or tab changes
  useEffect(() => {
    const fetchHighHandData = async () => {
      try {
        const data = await getHighHandData(location, i18n);
        if (data) {
          const { highHandData, highHandKey } = data;
          setHighHandContent(highHandData[tab]);
          setHighHandsTabs(highHandData.tabs);
          setKey(highHandKey);
        } else {
          setIsNotFound(true);
        }
      } catch (error) {
        console.error("Failed to load tournament content:", error);
        setIsNotFound(true);
      }
    };

    fetchHighHandData();
  }, [location, i18n.language, tab]);

  // Fetch data for cash prizes if the key exists
  useEffect(() => {
    fetchData("mystery-high-hand");
  }, [fetchData, i18n.language]);

  // Function to add a dollar sign to the amount
  const addDollarSign = (amount) => formatAmount(amount, lng);

  const cashPrizesData = data["mystery-high-hand"] || [];

  // Update tab hash in URL based on active tab
  useLayoutEffect(() => {
    if (tab !== "highHand") {
      const translatedTab = tabTranslations[lng][tab] || tab;
      const hash = `#${translatedTab}`;
      window.history.replaceState(null, "", location.pathname + hash);
    } else {
      window.history.replaceState(null, "", location.pathname);
    }
  }, [tab, location.pathname, lng]);

  useLayoutEffect(() => {
    const hash = window.location.hash.replace("#", "");
    const translatedTab = Object.keys(tabTranslations[lng]).find(
      (key) => tabTranslations[lng][key] === hash
    );
    setTab(translatedTab || "highHand"); // Default to "highHand" if no hash
  }, [location.hash, lng]); // Re-run when hash or language changes

  const handleTabChange = (k) => {
    setTab(k);
  };
  if (!highHandContent && !isNotFound) {
    return null;
  }
  if (isNotFound) {
    return <Page404 />;
  }
  const { hero, bgColor } = highHandContent;
  const showCashPrizes = getScheduleDates(key);
  return (
    <Div>
      <Hero
        image={hero?.image}
        bgImage={hero?.bgImage}
        imageAlt={hero?.imageAlt}
        bgColor={bgColor || "#000"}
        description={hero?.description}
        descriptionBgColor={hero?.descriptionBgColor || "#00000080"}
        fluidImage={hero?.image ? false : true}
      />
      <EventFinished scheduleKeyName={key} eventType="promotion" />
      <container-div>
        {highHandTabs && (
          <Tabs
            id="controlled-tab-example"
            activeKey={tab}
            onSelect={handleTabChange}
            className="gap-2 mt-5"
            variant="pills"
          >
            {Object.keys(highHandTabs)?.map((activetab) => (
              <Tab
                eventKey={activetab}
                title={highHandTabs[activetab]}
                key={activetab}
              />
            ))}
          </Tabs>
        )}
        <div className="high-hand">
          <div>
            <TitleSection
              as={"h2"}
              title={highHandContent?.title}
              text={highHandContent?.text}
            />
            <div>
              {highHandContent?.howItWorks && (
                <div className="how-it-works">
                  <ReactMarkdown>
                    {highHandContent?.howItWorks?.title}
                  </ReactMarkdown>
                  <ReactMarkdown>
                    {highHandContent?.howItWorks?.text}
                  </ReactMarkdown>
                </div>
              )}
              {highHandContent?.cashPrizes && shouldPublish(showCashPrizes) && (
                <div className="cash-prizes">
                  <h4>{t("poker.mystery-high-hand.cash-prize-available")}</h4>
                  <table>
                    <thead>
                      <tr>
                        <th>{t("poker.mystery-high-hand.remaining")}</th>
                        <th>{t("poker.mystery-high-hand.amount")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cashPrizesData ? (
                        cashPrizesData?.map(
                          (item, index) =>
                            item?.amount !== "" && (
                              <tr key={index}>
                                <td>{item.remaining}</td>
                                <td>{addDollarSign(item.amount)}</td>
                              </tr>
                            )
                        )
                      ) : (
                        <>
                          <tr>
                            <td></td>
                            <td>$1,000</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>$800</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>$400</td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>$200</td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
              <Row className="periods pb-3">
                <Col>
                  <LuAlarmClock />
                  <TitleSection
                    as={"h3"}
                    title={highHandContent?.qualifyingPeriods?.title}
                    text={highHandContent?.qualifyingPeriods?.text}
                    className="py-0"
                  />
                </Col>
                <Col>
                  <BiSolidDollarCircle />
                  <TitleSection
                    as={"h3"}
                    title={highHandContent?.payoutPeriods?.title}
                    text={highHandContent?.payoutPeriods?.text}
                    className="py-0"
                  />
                </Col>
              </Row>
              {highHandContent?.smallInfo && (
                <ReactMarkdown>{highHandContent?.smallInfo}</ReactMarkdown>
              )}
              {highHandContent?.titan && (
                <Row className="d-flex">
                  <StyledCol xs={12} md={6}>
                    <ImageLink
                      to={t("routes.titanTuesday2025")}
                      imgSrc={`promotions/poker/titan-tuesday-2025/TT-logo-2400x2400px-${i18n.language}.png`}
                      alt={"Titan Tuesday 2025"}
                      className="card-image-top"
                    />
                  </StyledCol>
                  <StyledCol xs={12} md={6}>
                    <ReactMarkdown>
                      {highHandContent?.titan?.text}
                    </ReactMarkdown>
                    <Button
                      className={"mt-0 mb-2"}
                      href={t("routes.titanTuesday2025")}
                      bgColor="var(--white)"
                      color="var(--black)"
                      hoverColor="var(--black)"
                      hoverBgColor="var(--white)"
                      label={t("button.learn-more")}
                      size="medium"
                    />
                  </StyledCol>
                </Row>
              )}
              {highHandContent?.nationalPokerDay && (
                <Row className="d-flex">
                  <StyledCol xs={12} md={6}>
                    <ReactMarkdown>
                      {highHandContent?.nationalPokerDay?.text}
                    </ReactMarkdown>
                    <Button
                      className={"mt-0 mb-2"}
                      href={t("highHands.nationalPokerDay2025")}
                      bgColor="var(--white)"
                      color="var(--black)"
                      hoverColor="var(--black)"
                      hoverBgColor="var(--white)"
                      label={t("button.learn-more")}
                      size="medium"
                    />
                  </StyledCol>
                  <StyledCol xs={12} md={6}>
                    <ImageLink
                      to={t("highHands.nationalPokerDay2025")}
                      imgSrc={`promotions/poker/national-poker-day-2025-v2/NPD-logo-${i18n.language}-2400x2400px.png`}
                      alt={highHandContent?.nationalPokerDay?.title}
                      className="card-image-top"
                    />
                  </StyledCol>
                </Row>
              )}
              <CashGamesInfo />
            </div>
          </div>
          <div>
            <h4>{highHandContent?.generalRules?.title}</h4>
            <ReactMarkdown>{highHandContent?.generalRules?.list}</ReactMarkdown>
          </div>
        </div>
      </container-div>
    </Div>
  );
};

const Div = styled.div`
  width: 100%;
  position: relative;
  background-color: #010101;
  .hero_text_container {
    display: none;
  }
  .title-section {
    padding: 40px 0 0 0;
  }
  div.periods {
    text-align: center;
    svg {
      margin: 20px 0 10px;
      color: var(--red);
      font-size: 40px;
    }
  }
  .high-hand {
    margin: 20px 0;
    & > div:first-child {
      width: 100%;
      max-width: 1440px;
      height: auto;
      margin: 0 auto;
      color: var(--white);
      & > h3 {
        font-weight: 700;
        font-size: 20px;
        margin-top: 0px;
        padding-bottom: 20px;
        text-transform: uppercase;
      }
      & > div {
        width: 100%;
        & > h4 {
          background: rgb(170, 11, 11);
          background: linear-gradient(
            45deg,
            rgba(170, 11, 11, 1) 0%,
            rgba(0, 0, 0, 1) 50%,
            rgba(170, 11, 11, 1) 100%
          );
          color: var(--white);
          font-weight: 700;
          text-transform: uppercase;
          text-align: center;
          padding: 15px;
          max-width: 500px;
          font-size: 30px;
          margin: 20px auto;
          border-radius: 5px;
          line-height: 25px;
        }
        & > p {
          margin: 0 0 10px;
          font-size: 18px;
          color: #aaa;
          text-align: center;
        }
      }
    }
    & > div:nth-child(2) {
      background-color: #111;
      width: 100%;
      max-width: 1440px;
      margin: 40px auto 0 auto;
      padding: 2rem 1rem;
      & > h4 {
        font-weight: 700;
        color: #999;
        text-transform: uppercase;
        text-align: center;
        padding: 15px;
        font-size: 30px;
        margin: 5px 0 10px 0;
      }
      & > ul {
        margin-top: 0;
        margin-bottom: 10px;
        list-style: decimal;
        & > li {
          font-size: 16px;
          color: #999;
          ul {
            list-style: lower-alpha;
            ul {
              list-style: lower-roman;
            }
          }
        }
      }
    }
  }
  .how-it-works {
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--lightblack);
    padding: 40px;
    ul {
      list-style: none;
      text-align: left;
      padding-left: 20px;
      li {
        margin-bottom: 10px;
      }
    }
  }
  .cash-prizes {
    & > h4 {
      background: rgb(170, 11, 11);
      background: linear-gradient(
        45deg,
        rgba(170, 11, 11, 1) 0%,
        rgba(0, 0, 0, 1) 50%,
        rgba(170, 11, 11, 1) 100%
      );
      color: var(--white);
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
      padding: 15px;
      max-width: 500px;
      font-size: 30px;
      margin: 20px auto;
      border-radius: 5px;
      line-height: 25px;
    }
    & > table {
      width: 100%;
      max-width: 100%;
      margin: 20px 0px 40px 0px;
      background: var(--black);
      color: var(--white);
      border: 1px solid var(--lightblack);
      border-spacing: 0;
      border-collapse: collapse;
      & > thead {
        & > tr {
          & > th {
            text-align: center;
            font-size: 20px;
            font-weight: 400;
            width: 50%;
            background-color: #333;
            border: 1px solid #222;
            padding: 8px;
            line-height: 1.42857143;
          }
        }
      }
      & > tbody {
        & > tr {
          & > td:first-child {
            text-align: center;
            font-size: 30px;
            font-weight: 700;
            width: 50%;
            color: var(--white);
            background-color: #230202;
            border: 1px solid #222;
            padding: 8px;
            line-height: 1.42857143;
          }
          & > td:last-child {
            background-color: #aa0b0b;
            text-align: center;
            font-size: 30px;
            font-weight: 700;
            width: 50%;
            color: #fff;
            border: 1px solid #222;
            padding: 8px;
            line-height: 1.42857143;
          }
        }
      }
    }
  }
  .nav-link,
  .nav-pills .nav-link.active,
  .nav-pills .show > .nav-link {
    padding: 1rem;
    background-color: ${(props) =>
      props.bgColor || "var(--mutedred)"} !important;
    color: ${(props) => props.textColor || "white"};
    border-right: 1px solid
      ${(props) => props.borderColor || "var(--lightblack)"};
    border-bottom: 1px solid
      ${(props) => props.borderColor || "var(--lightblack)"};
    &:hover,
    &.active,
    &:focus {
      background-color: ${(props) => props.active || "var(--red)"} !important;
      color: ${(props) => props.textColor || "var(--white)"};
    }
  }
  .nav-pills {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .nav-link {
    width: 100%;
  }
  @media screen and (max-width: 991px) {
    .nav-pills {
      grid-template-columns: 1fr;
    }
    .how-it-works {
      padding: 20px;
    }
  }
`;

const StyledCol = styled(Col)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  p {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }
  @media screen and (max-width: 991px) {
    p {
      font-size: 1.2rem;
    }
  }
`;
export default HighHandMain;
