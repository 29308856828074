import styled, { css } from "styled-components";
import Hero from "src/components/Hero/Hero";
import {
  Row,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  Spinner,
  Alert,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Button from "src/components/Button/Button";
import { useState, useContext } from "react";
import { privateEvents } from "src/api/events";
import ResponsiveImage from "src/components/ImageWrapper/ResponsiveImage";
import TitleSection from "src/components/TitleSection/TitleSection";
import { useToast } from "src/context/toast/toast.provider";
import { useIsLgScreenUp } from "src/utils/windowUtils";

const RoomsForRentForm = () => {
  const { t } = useTranslation();
  const { showToastFn } = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const isLgScreenUp = useIsLgScreenUp();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setIsSuccess(false);

    const formData = new FormData(event.target);
    const eventTypeSelect = event.target.elements.event_type_key;
    const eventTypeString = eventTypeSelect.options[eventTypeSelect.selectedIndex].text;

    try {
      formData.append("event_type_string", eventTypeString);
      const response = await privateEvents(formData);

      if (!response.success) {
        throw new Error("Failed to submit form");
      }

      setIsSuccess(true);

      const heroEl = document.querySelector(".hero_container");
      const header = document.querySelector("#header-navbar");
      let scrollHeight = heroEl?.offsetHeight || 0;
      if (isLgScreenUp) {
        scrollHeight -= header?.offsetHeight || 0;
      }
      window.scrollTo({ top: scrollHeight, behavior: "smooth" });

    } catch (error) {
      // showToastFn({
      //   show: true,
      //   title: t("modal.tokenExpired.error.title"),
      //   message: response.message,
      //   autohide: false,
      //   variant: "danger",
      // });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <RoomsForRentDiv>
      <Hero
        bgImage="private-events/Event-form-header-2400x800-v2.jpg"
        buttonSettings={null}
        description={t("roomsForRent.form.hero.title")}
        descriptionBgColor="#0000004d"
      ></Hero>
        {isSuccess && (
          <SuccessOverlay id="event-form-success">
            <ResponsiveImage src="svg/Check.svg" alt="check-icon" />
            <h2>{t("roomsForRent.form.success.title")}</h2>
            <p>{t("roomsForRent.form.success.text")}</p>
          </SuccessOverlay>
        )}
      {!isSuccess && (
        <FormContainer>
          <StyledForm onSubmit={handleSubmit} className="p-0">
            <TitleSection
              as="h2"
              title={t("roomsForRent.form.hero.subTitle")}
              text={t("roomsForRent.form.hero.text")}
              dark={true}
            />
            <Row className="g-3">
              <Col md={6}>
                <FormGroup>
                  <FormLabel>{t("roomsForRent.form.inputs.firstName")}</FormLabel>
                  <FormControl type="text" name="first_name" required />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <FormLabel>{t("roomsForRent.form.inputs.lastName")}</FormLabel>
                  <FormControl type="text" name="last_name" required />
                </FormGroup>
              </Col>
            </Row>

            <Row className="g-3 mb-4">
              <Col md={6}>
                <FormGroup>
                  <FormLabel>{t("roomsForRent.form.inputs.email")}</FormLabel>
                  <FormControl type="email" name="email" required />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <FormLabel>{t("roomsForRent.form.inputs.phone")}</FormLabel>
                  <FormControl
                    type="tel"
                    name="phone"
                    pattern="(\d{3})[-]?(\d{6})[-]?(\d{8})"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="g-3">
              <Col md={6}>
                <FormGroup>
                  <FormLabel>{t("roomsForRent.form.inputs.company")}</FormLabel>
                  <FormControl type="text" name="company_name" />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <FormLabel>{t("roomsForRent.form.inputs.eventDate")}</FormLabel>
                  <FormControl type="date" name="date" required />
                </FormGroup>
              </Col>
            </Row>

            <Row className="g-3">
              <Col md={6}>
                <FormGroup>
                  <FormLabel>
                    {t("roomsForRent.form.inputs.numberOfGuests")}
                  </FormLabel>
                  <FormControl
                    type="number"
                    name="number_of_guests"
                    min="10"
                    defaultValue="10"
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <FormLabel>{t("roomsForRent.form.inputs.eventType")}</FormLabel>
                  <Form.Select
                    as="select"
                    type="select"
                    name="event_type_key"
                    required
                  >
                    <option value="">
                      {t("roomsForRent.form.inputs.selectTypeOfEvent")}
                    </option>
                    <option value="networking">
                      {t("roomsForRent.form.eventTypes.networkingEvent")}
                    </option>
                    <option value="corporate">
                      {t("roomsForRent.form.eventTypes.corporateGathering")}
                    </option>
                    <option value="team">
                      {t("roomsForRent.form.eventTypes.teamBuilding")}
                    </option>
                    <option value="happyhour">
                      {t("roomsForRent.form.eventTypes.happyHour")}
                    </option>
                    <option value="bachelor">
                      {t("roomsForRent.form.eventTypes.bachelorParty")}
                    </option>
                    <option value="staff">
                      {t("roomsForRent.form.eventTypes.staffParty")}
                    </option>
                    <option value="birthday">
                      {t("roomsForRent.form.eventTypes.birthdayParty")}
                    </option>
                    <option value="shows">
                      {t("roomsForRent.form.eventTypes.shows")}
                    </option>
                    <option value="restaurant">
                      {t("roomsForRent.form.eventTypes.restaurantReservation")}
                    </option>
                    <option value="poker">
                      {t("roomsForRent.form.eventTypes.poker")}
                    </option>
                    <option value="bus">
                      {t("roomsForRent.form.eventTypes.busGroup")}
                    </option>
                  </Form.Select>
                </FormGroup>
              </Col>
            </Row>



            <Row className="g-3">
              <Col className="mt-4">
                <FormGroup>
                  <FormLabel>{t("roomsForRent.form.inputs.message")}</FormLabel>
                  <FormControl as="textarea" name="message" rows={3} required />
                </FormGroup>
              </Col>
            </Row>

            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

            <Button
              type="submit"
              disabled={isLoading}
              bgColor="var(--red)"
              color="var(--white)"
              hoverColor="var(--white)"
              hoverBgColor="var(--black)"
              width="wide"
              label={
                isLoading ? (
                  <Spinner as="span" animation="border" size="sm" />
                ) : (
                  t("roomsForRent.form.submit")
                )
              }
            />
          </StyledForm>
        </FormContainer>
      )}
    </RoomsForRentDiv>
  );
};

const RoomsForRentDiv = styled.div`
  width: 100%;
  height: auto;
  background: var(--white);
  color: var(--black);
  container-div {
    background-color: unset !important;
  }
  .hero_container {
    min-height: 500px;
  }
  .hero_text_container {
    h2 {
      max-width: 800px;
      font-size: 45px;
    }
  }
  .small {
    text-align: center;
  }
  .title-section {
    ${css({
      textWrap: "balance",
    })}
    h2 {
      padding: 0;
    }
    p {
      max-width: 960px;
      padding: 0;
    }
  }
  .icons {
    width: 24px;
    margin-right: 1rem;
  }
  .col {
    display: flex;
    flex-direction: column;
  }
  .flex-row {
    display: flex;
    align-items: center;
    padding-bottom: 10px;
    p {
      margin: 0;
    }
  }
  .list-row {
    display: flex;
    justify-content: center;
    max-width: 960px;
    margin: 0 auto;
  }
  .title-section-book {
    padding-bottom: 0;
  }
  .contact-us {
    max-width: 1100px;
    margin: 1rem auto;
    img {
      max-height: 250px;
      object-fit: cover;
    }
  }
  @media (max-width: 768px) {
    .hero_text_container {
      h2 {
        font-size: 32px;
      }
    }
    .col {
      margin: 0;
      display: flex;
      justify-content: center;
    }
    .title-section {
      margin: 2rem 0;
      padding: 0;
    }
  }
`;
const FormContainer = styled.div`
  position: relative;
  padding: 20px;
  max-height: fit-content;
`;
const SuccessOverlay = styled.div`
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
  /* height: 100%; */
  background: rgba(0, 0, 0, 0.95);
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 3rem 3rem 4rem;
  z-index: 3;
  img {
    max-width: 3rem;
    margin: 0.5rem;
  }
`;
const StyledForm = styled(Form)`
  max-width: 960px;
  display: flex;
  flex-flow: column nowrap;
  gap: 10px;
  padding: 2rem;
  margin: 0 auto;

  @media (max-width: 576px) {
    padding: 1rem;
    margin: 1rem 0;
  }

  button,
  .button {
    margin-top: 25px;
  }

  .form-link,
  .form-label {
    margin-bottom: 5px;
    font-size: 14px;
    color: #333; /* Darker text for contrast */
  }

  .label-link-container {
    display: flex;
    justify-content: space-between;

    .form-link {
      color: #d83933; /* Red for better visual alignment */
      text-align: right;

      &:hover {
        color: #b5302b; /* Darker red on hover */
      }
    }
  }
`;

export default RoomsForRentForm;
