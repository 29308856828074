import { useEffect } from "react";

const QuantcastPixel = () => {
  useEffect(() => {
    window._qevents = window._qevents || [];

    // Inject the Quantcast script if it doesn't already exist
    (function () {
      const elem = document.createElement('script');
        elem.src = (document.location.protocol == "https:" ? "https://secure" : "http://edge") + ".quantserve.com/quant.js";
        elem.async = true;
        elem.type = "text/javascript";
        const scpt = document.getElementsByTagName('script')[0];
        scpt.parentNode.insertBefore(elem, scpt);
    })();

    // Push tracking event
    window._qevents.push({
      qacct: "p-vXc3n0eGxvxS3",
      labels: "_fp.event.PageView"
    });
  }, []); // ✅ Runs only once on mount

  return null;
};

export default QuantcastPixel;
